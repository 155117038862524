import styled from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";

import HeroProject from "./components/HeroProject";
import FeaturesProject from "./components/FeaturesProject";
import useWindowScroll from "../hooks/useWindowScroll";
import { useEffect } from "react";
import ProcessProject from "./components/ProcessProject";
import PerformanceProject from "./components/PerformanceProject";

const StudyCase = () => {
  useWindowScroll();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Container>
        <HeroProject />
        <FeaturesProject/>
        <ProcessProject/>
        <PerformanceProject/>
        <Footer />
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  max-width: 100vw;
  overflow-x: hidden;
`;

export default StudyCase;
