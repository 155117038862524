import styled, { keyframes } from "styled-components";
import logoWhiteImg from "../assets/images/logo-black.svg";
import logomobile from "../assets/images/logomobile.svg";
import menumobile from "../assets/images/menumobile.svg";
import closeImg from "../assets/images/close.svg";
import menuImg from "../assets/images/menu.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useWindowSize from "../hooks/useWindowSize";
import { useState, useEffect } from "react";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { useTheme } from "../context/theme";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const { t } = useTranslation();
  const langSelected = localStorage.getItem("i18nextLng") || "en";
  const windowSize = useWindowSize();
  const [menuOpened, setMenuOpened] = useState(false);
  const { project } = useParams();
  const isMobile = windowSize.width <= 480;
  const navigate = useNavigate();

  const scrollToTop = () => scroll.scrollToTop();
  const redirectToHome = () => {
    navigate("/");
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0].style;

    if (menuOpened) {
      if (isMobile) {
        body.overflowY = "hidden";
      }
    }

    if (!menuOpened || !isMobile) {
      body.overflowY = "auto";
    }
  }, [menuOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const [theme, setTheme] = useTheme();

  return (
    <Container
      style={{
        height: windowSize.width <= 480 && menuOpened ? "100vh" : "unset",
        position:
          windowSize.width <= 480
            ? "relative"
            : !project
            ? "sticky"
            : "relative",
      }}
      isFloating={!project ? scrollPosition > 60 : false}
    >
      {windowSize.width > 480 ? (
        <HeaderLogo
          src={logoWhiteImg}
          onClick={!project ? scrollToTop : redirectToHome}
        />
      ) : null}

      <HeaderRigth>
        <Menu>
          <Ul className={menuOpened ? "opened" : "closed"}>
            {!project
              ? [1, 2, 3].map((_, index) => (
                  <Li key={index + 20}>
                    <ScrollLink
                      to={`#sec${index + 2}`}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      <Link onClick={() => setMenuOpened(false)}>
                        {t(`menu${index + 1}`)}
                      </Link>
                    </ScrollLink>
                  </Li>
                ))
              : [1, 2].map((_, index) => (
                  <Li key={index + 10}>
                    <ScrollLink
                      to={`#sec${index + 1}`}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      <Link onClick={() => setMenuOpened(false)}>
                        {t(`studyMenu${index + 1}`)}
                      </Link>
                    </ScrollLink>
                  </Li>
                ))}
          </Ul>
        </Menu>

        {!project && !isMobile && (
          <MenuButton
            style={isMobile ? { transform: "scale(0.5)", width: "80px" } : {}}
            onClick={() => setMenuOpened((r) => !r)}
          >
            {menuOpened ? (
              <MenuIcon src={closeImg} />
            ) : (
              <MenuIcon src={isMobile ? menumobile : menuImg} />
            )}
          </MenuButton>
        )}
        {isMobile && (
          <MenuButton
            style={isMobile ? { transform: "scale(0.5)", width: "30px", opacity: 0 } : {}}
            onClick={() => setMenuOpened((r) => !r)}
          >
            {menuOpened ? (
              <MenuIcon src={closeImg} />
            ) : (
              <MenuIcon src={isMobile ? menumobile : menuImg} />
            )}
          </MenuButton>
        )}

        {isMobile ? (
          <>
            <HeaderLogo
              style={{ width: "100px" }}
              src={logomobile}
              onClick={!project ? scrollToTop : redirectToHome}
            />
            <SwitchParentContainer
              style={{
                padding: "0",
                transform: "scale(0.7)",
                marginLeft: "-20px",
                marginBottom: "10px",
              }}
            >
              <SwitchContainer
                onClick={() => {
                  langSelected === "es"
                    ? i18next.changeLanguage("en")
                    : i18next.changeLanguage("es");
                }}
              >
                <SwitchBall active={langSelected === "es" ? false : true}>
                  <LabelLang>EN</LabelLang>
                </SwitchBall>
                <SwitchBall active={langSelected === "es" ? true : false}>
                  <LabelLang>ES</LabelLang>
                </SwitchBall>
              </SwitchContainer>
              <Wrapper style={{ transform: "scale(0.45)" }}>
                <Checkbox
                  type="checkbox"
                  id="hide-checkbox"
                  checked={theme === "dark" ? false : true}
                />
                <LabelSwitch
                  htmlFor="hide-checkbox"
                  onClick={() =>
                    theme === "dark" ? setTheme("light") : setTheme("dark")
                  }
                  style={{ marginLeft: "-50px" }}
                >
                  <ToggleButton
                    style={{
                      background: theme === "light" ? "#ffdb2e" : "#faeaf1",
                    }}
                  >
                    <Crater1></Crater1>
                    <Crater2></Crater2>
                    <Crater3></Crater3>
                    <Crater4></Crater4>
                    <Crater5></Crater5>
                    <Crater6></Crater6>
                    <Crater7></Crater7>
                  </ToggleButton>
                  <Star1></Star1>
                  <Star2></Star2>
                  <Star3></Star3>
                  <Star4></Star4>
                  <Star5></Star5>
                  <Star6></Star6>
                  <Star7></Star7>
                  <Star8></Star8>
                </LabelSwitch>
              </Wrapper>
            </SwitchParentContainer>
          </>
        ) : null}

        {isMobile ? <div style={{ width: "70px", height: "100%" }} /> : null}

        {!isMobile ? (
          <ScrollLink
            to="#sec8"
            smooth={true}
            offset={isMobile ? -100 : -30}
            duration={500}
          >
            {!project && <LetsTalk>{t("letsTalk")}</LetsTalk>}
          </ScrollLink>
        ) : null}

        {windowSize.width > 480 && (
          <SwitchContainer
            onClick={() =>
              langSelected === "es"
                ? i18next.changeLanguage("en")
                : i18next.changeLanguage("es")
            }
          >
            <SwitchBall active={langSelected === "es" ? false : true}>
              <LabelLang>EN</LabelLang>
            </SwitchBall>
            <SwitchBall active={langSelected === "es" ? true : false}>
              <LabelLang>ES</LabelLang>
            </SwitchBall>
          </SwitchContainer>
        )}

        {windowSize.width > 480 && (
          <Wrapper>
            <Checkbox
              type="checkbox"
              id="hide-checkbox"
              checked={theme === "dark" ? false : true}
            />
            <LabelSwitch
              htmlFor="hide-checkbox"
              onClick={() =>
                theme === "dark" ? setTheme("light") : setTheme("dark")
              }
              style={{ marginLeft: "-50px" }}
            >
              <ToggleButton
                style={{
                  background: theme === "light" ? "#ffdb2e" : "#faeaf1",
                }}
              >
                <Crater1></Crater1>
                <Crater2></Crater2>
                <Crater3></Crater3>
                <Crater4></Crater4>
                <Crater5></Crater5>
                <Crater6></Crater6>
                <Crater7></Crater7>
              </ToggleButton>
              <Star1></Star1>
              <Star2></Star2>
              <Star3></Star3>
              <Star4></Star4>
              <Star5></Star5>
              <Star6></Star6>
              <Star7></Star7>
              <Star8></Star8>
            </LabelSwitch>
          </Wrapper>
        )}
      </HeaderRigth>
      {windowSize.width <= 480 && (
        <MobileMenu
          style={{
            left: menuOpened ? 0 : -550,
            opacity: menuOpened ? 1 : 0,
            transition: menuOpened ? "all .3s" : "all .8s",
          }}
        >
          <MenuButtonX onClick={() => setMenuOpened((r) => !r)}>
            <MenuIcon src={closeImg} />
          </MenuButtonX>
          <div style={{ height: "20px" }} />
          <Ul mobile>
            {!project
              ? [1, 2, 3].map((_, index) => (
                  <Li key={index + 20}>
                    <ScrollLink
                      to={`#sec${index + 2}`}
                      smooth={true}
                      offset={-830}
                      duration={500}
                    >
                      <Link onClick={() => setMenuOpened(false)}>
                        {" "}
                        {t(`menu${index + 1}`)}
                      </Link>
                    </ScrollLink>
                  </Li>
                ))
              : [1, 2].map((_, index) => (
                  <Li key={index + 10}>
                    <ScrollLink
                      to={`#sec${index + 1}`}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      <Link onClick={() => setMenuOpened(false)}>
                        {t(`studyMenu${index + 1}`)}
                      </Link>
                    </ScrollLink>
                  </Li>
                ))}
          </Ul>
          <div style={{ height: "20px" }} />

          {menuOpened ? (
            <>
              {!project && (
                <LetsTalk allWidth>
                  <ScrollLink
                    to="#sec8"
                    smooth={true}
                    offset={-850}
                    duration={500}
                    onClick={() => setMenuOpened(false)}
                  >
                    {t("letsTalk")}
                  </ScrollLink>
                </LetsTalk>
              )}
            </>
          ) : null}
        </MobileMenu>
      )}
    </Container>
  );
}

const Container = styled.div<{ isFloating: boolean }>`
  transition: all 0.3s;
  box-shadow: ${({ isFloating }) =>
    isFloating ? "0px 10px 50px -7px rgba(0, 0, 0, 0.2)" : ""};
  padding: 0px 16px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  .closed {
    transform: translateX(720px);
    opacity: 0;
  }
  top: 0;
  z-index: 110;
  position: sticky;
  background-color: ${({ theme }) => theme.palette.background};
  @media (max-width: 480px) {
    padding: 0;
    position: sticky;
    top: 0;
  }
  max-width: 100%;
  overflow: hidden;
`;
const HeaderLogo = styled.img`
  filter: ${({ theme }) =>
    theme.selected === "light" ? "invert(0)" : "invert(100%)"};
  width: calc(15% + 1vw);
  height: 60px;
  object-fit: contain;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 150px;
    margin-bottom: 10px;
  }
`;
const HeaderRigth = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
  }
`;
const MenuIcon = styled.img`
  width: 50px;
  height: 20px;

  @media (max-width: 480px) {
    width: 70px;
    height: 30px;
  }
`;
const LetsTalk = styled.a<{ allWidth?: Boolean }>`
  border: 2px solid #44c1a9;
  color: #44c1a9;
  border-radius: 5px;
  background: none;
  display: flex;
  width: 130px;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
  text-decoration: none;
  margin: 0 10px;
  padding: 0 10px;

  @media (max-width: 480px) {
    width: 80%;
    border: 3px solid #44c1a9;
    font-size: 30px;
    padding: 10px 20px;
  }
`;
const SwitchParentContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const SwitchContainer = styled.button`
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  border-radius: 25px;
  display: flex;
  cursor: pointer;
  border: none;
  margin-left: 16px;
  @media (max-width: 480px) {
    border-radius: 22.5px;
    margin-left: 0;
  }
`;
const SwitchBall = styled.div<{ active?: Boolean }>`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: none;
  background-color: ${({ active, theme }) => (active ? "#D8670C" : "")};
  color: ${(p) => (p.active ? "#ffffff" : "#D8670C")};
  @media (max-width: 480px) {
    width: 45px;
    height: 45px;
    border-radius: 22.5px;
  }
`;
const LabelLang = styled.label`
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;
const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 8px;
  padding: 8px;
  @media (max-width: 480px) {
    margin: 0;
  }
`;
const MenuButtonX = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 8px;
  padding: 8px;
  @media (max-width: 480px) {
    margin: 10px 0 0 0;
  }
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;
const Ul = styled.ul<{ mobile?: Boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.mobile ? "column" : "row")};
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  list-style: none;
  transition: all 0.7s;
  @media (max-width: 480px) {
    width: 100%;
    display: ${(p) => (p.mobile ? "flex" : "none")};
    align-items: flex-start;
  }
`;
const Li = styled.li`
  margin: 10px 0;
  @media (max-width: 480px) {
    margin: 10px 5px;
  }
`;
const Link = styled.a`
  color: ${({ theme }) => theme.palette.text};
  padding: 4px 14px;
  cursor: pointer;
  font-size: 21px;
  text-decoration: none;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const MobileMenu = styled.div`
  position: absolute;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  padding: 16px 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  transition: all 0.7;
  opacity: 1;
  .opened {
    opacity: 1;
  }
  .closed {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  transform: scale(0.38);
`;

const LabelSwitch = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 200px;
  height: 100px;
  background: #211042;
  border-radius: 50px;
  transition: 500ms;
  overflow: hidden;
`;

const ToggleButton = styled.span`
  position: absolute;
  display: inline-block;
  top: 7px;
  left: 6px;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  background: #faeaf1;
  overflow: hidden;
  box-shadow: 0 0 35px 4px rgba(255, 255, 255);
  transition: all 500ms ease-out;
`;

const Move = keyframes`
  0% {
    transform: none;
  }
  
  25% {
    transform: translateX(2px);
  }
  
  100% {
    transform: translateX(-2px);
  }`;

const Crater = styled.span`
  position: absolute;
  display: inline-block;
  background: #faeaf1;
  border-radius: 50%;
  transition: 500ms;
`;

const Crater1 = styled(Crater)`
  background: #fffff9;
  width: 86px;
  height: 86px;
  left: 10px;
  bottom: 10px;
`;
const Crater2 = styled(Crater)`
  width: 20px;
  height: 20px;
  top: -7px;
  left: 44px;
`;
const Crater3 = styled(Crater)`
  width: 16px;
  height: 16px;
  top: 20px;
  right: -4px;
`;
const Crater4 = styled(Crater)`
  width: 10px;
  height: 10px;
  top: 24px;
  left: 30px;
`;
const Crater5 = styled(Crater)`
  width: 15px;
  height: 15px;
  top: 40px;
  left: 48px;
`;
const Crater6 = styled(Crater)`
  width: 10px;
  height: 10px;
  top: 48px;
  left: 20px;
`;
const Crater7 = styled(Crater)`
  width: 12px;
  height: 12px;
  bottom: 5px;
  left: 35px;
`;

const Travel = keyframes`
 0% {
    transform: rotate(-45deg) translateX(70px);
  }

  50% {
    transform: rotate(-45deg) translateX(-20px);
    box-shadow: 5px 0px 6px 1px #FFF;
  }

  100% {
    transform: rotate(-45deg) translateX(-30px);
    width: 2px;
    height: 2px;
    opacity: 0;
    box-shadow: none;
  }`;
const Star = styled.span`
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 0 2px 2px rgba(255, 255, 255);
`;

const Star1 = styled(Star)`
  width: 6px;
  height: 6px;
  right: 90px;
  bottom: 40px;
`;
const Star2 = styled(Star)`
  width: 8px;
  height: 8px;
  right: 70px;
  top: 10px;
`;
const Star3 = styled(Star)`
  width: 5px;
  height: 5px;
  right: 60px;
  bottom: 15px;
`;
const Star4 = styled(Star)`
  width: 3px;
  height: 3px;
  right: 40px;
  bottom: 50px;
`;
const Star5 = styled(Star)`
  width: 4px;
  height: 4px;
  right: 10px;
  bottom: 35px;
`;

const Star6 = styled(Star)`
  right: 30px;
  bottom: 30px;
  animation-delay: -2s;
  width: 10px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  box-shadow: 5px 0px 4px 1px #fff;
  animation-name: ${Travel};
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
`;

const Star7 = styled(Star)`
  right: 50px;
  bottom: 60px;
  width: 10px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  box-shadow: 5px 0px 4px 1px #fff;
  animation-name: ${Travel};
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
`;

const Star8 = styled(Star)`
  right: 90px;
  top: 10px;
  animation-delay: -4s;
  width: 10px;
  height: 2px;
  border-radius: 2px;
  transform: rotate(-45deg);
  box-shadow: 5px 0px 4px 1px #fff;
  animation-name: ${Travel};
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
`;

const Checkbox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  :checked + ${LabelSwitch} {
    background: #24d7f7;
  }
  :checked + ${LabelSwitch} ${ToggleButton} {
    background: #f7ffff;
    transform: translateX(102px);
    box-shadow: 0 0 35px 5px rgba(255, 255, 255);
  }
  :checked + ${LabelSwitch} ${ToggleButton} ${Crater} {
    transform: rotate(-45deg) translateX(70px);
  }
  :checked + ${LabelSwitch} ${Star} {
    animation: ${Move} 2s infinite;
    transform: none;
    box-shadow: none;
  }
  :checked + ${LabelSwitch} ${Star1} {
    width: 40px;
    height: 10px;
    border-radius: 10px;
    background: #fff;
    left: 20px;
    top: 25px;
    box-shadow: none;
  }
  :checked + ${LabelSwitch} ${Star2} {
    width: 12px;
    height: 12px;
    background: #fff;
    left: 26px;
    top: 23px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  :checked + ${LabelSwitch} ${Star3} {
    height: 16px;
    background: #fff;
    left: 35px;
    top: 19px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  :checked + ${LabelSwitch} ${Star4} {
    width: 14px;
    height: 14px;
    background: #fff;
    left: 46px;
    top: 21px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  :checked + ${LabelSwitch} ${Star5} {
    width: 60px;
    height: 15px;
    border-radius: 15px;
    background: #fff;
    left: 30px;
    bottom: 20px;
    box-shadow: none;
  }
  :checked + ${LabelSwitch} ${Star6} {
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    left: 38px;
    bottom: 20px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  :checked + ${LabelSwitch} ${Star7} {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    left: 52px;
    bottom: 20px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  :checked + ${LabelSwitch} ${Star8} {
    width: 21px;
    height: 21px;
    background: #fff;
    border-radius: 50%;
    left: 70px;
    top: 59px;
    box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
  }
`;
