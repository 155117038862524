import { useTranslation } from "react-i18next";
import styled from "styled-components";
import lomoIcon1 from "../../assets/images/lomoIcon1.svg";
import lomoIcon1Dark from "../../assets/images/lomoIcon1-dark.svg";
import lomoIcon2 from "../../assets/images/lomoIcon2.svg";
import lomoIcon2Dark from "../../assets/images/lomoIcon2-dark.svg";
import lomoIcon3 from "../../assets/images/lomoIcon3.svg";
import lomoIcon3Dark from "../../assets/images/lomoIcon3-dark.svg";
import lomoIcon4 from "../../assets/images/lomoIcon4.svg";
import lomoIcon4Dark from "../../assets/images/lomoIcon4-dark.svg";
import { useTheme } from "../../context/theme";
type Item = {
  image: string;
  title: string;
  subtitle?: string;
  description: string;
};
const ProcessProject = () => {
  const { t } = useTranslation();
  const [theme] = useTheme();

  const items: Item[] = [
    {
      image: theme === "light" ? lomoIcon1 : lomoIcon1Dark,
      title: t("discovery"),
      description: t("endDiscovery"),
    },
    {
      image: theme === "light" ? lomoIcon2 : lomoIcon2Dark,
      title: t("design"),
      description: t("endDesign"),
    },
    {
      image: theme === "light" ? lomoIcon3 : lomoIcon3Dark,
      title: t("development"),
      description: t("endDevelopment"),
    },
    {
      image: theme === "light" ? lomoIcon4 : lomoIcon4Dark,
      title: t("deployment"),      
      description: t("endDeployment"),
    },
  ];

  return (
    <Container id="#sec1">
      <Section>
        <Side>
          <Logo src={items[0].image} />
        </Side>
        <Center>
          <Title>{items[0].title}</Title>
          <Subtitle>{items[0].description}</Subtitle>
        </Center>
        <SideRight></SideRight>
      </Section>
      <Section>
        <Side></Side>
        <CenterRight>
          <Title>{items[1].title}</Title>
          <SubtitleRight>{items[1].description}</SubtitleRight>
        </CenterRight>
        <SideRight>
          <Logo src={items[1].image} />
        </SideRight>
      </Section>
      <Section>
        <Side>
          <Logo src={items[2].image} />
        </Side>
        <Center>
          <Title>{items[2].title}</Title>
          <Subtitle>{items[2].description}</Subtitle>
        </Center>
        <SideRight></SideRight>
      </Section>
      <Section>
        <Side></Side>
        <CenterRight>
          <Title>{items[3].title}</Title>
          <SubtitleRight>{items[3].description}</SubtitleRight>
        </CenterRight>
        <SideRight>
          <Logo src={items[3].image} />
        </SideRight>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 100vw;
  gap: 32px;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.gradients[0][1]} 0%,${theme.palette.background} 30%, ${theme.palette.background} 70%,  ${theme.palette.gradients[0][1]} 100%)`};
  padding: 64px 16px 64px 16px;
  @media (max-width: 540px) {
    flex-direction: column;
    padding: 0 16px;
    width: 90%;
  }
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 132px;
  @media (max-width: 540px) {
    min-width: 80px;
  }
`;
const SideRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 132px;
  @media (max-width: 540px) {
    min-width: 80px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  gap: 32px;
  @media (max-width: 540px) {
    gap: 16px;
    margin-top: 32px;
    &:nth-child(even) {
      flex-direction: row-reverse;
      ${Side} {
        display: none;
      }
    }
    &:nth-child(odd) {
      ${SideRight} {
        display: none;
      }
    } 
  }
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 540px) {
    margin-left: 0;
  }
`;
const CenterRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 540px) {
    margin-left: 0;
  align-items: flex-start;

  }
`;
const Logo = styled.img`
  width: 100px;
  height: 100px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.text};
  border-radius: 50%;
  @media (max-width: 540px) {
    width: 40px;
    height: 40px;
  }
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: left;
  margin-bottom: 12px;
  font-size: 65px;
  &::first-letter {
    color: #44c1a9;
  }
  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
    width: 60%;
  }
`;
const Subtitle = styled.label`
  color: ${({ theme }) => theme.palette.text};
  text-align: left;
  margin-bottom: 20px;
  font-size: 23px;
  @media (max-width: 540px) {
    text-align: justify;
    font-size: 16px;
    width: 100%;
  }
`;
const SubtitleRight = styled.label`
  color: ${({ theme }) => theme.palette.text};
  text-align: right;
  margin-bottom: 20px;
  font-size: 23px;
  @media (max-width: 540px) {
    text-align: justify;
    font-size: 16px;
    width: 100%;
  }
`;

export default ProcessProject;
