export const palette = {
  light: {
    primary: "#44C1A9",
    background: "#ffffff",
    black: "#09152A",
    blackBlue: "#1A263A",
    text: "#09152A",
    blue: "#2732BF",
    orange: "#D8670C",
    white: "#FFFFFF",
    gray: "#ADADAD",
    gradients: [
      ["#EDF0F6", "#EDF0F6"],
      ["#FFFFFF", "#F6F6F6", "#E5E5E5"],
    ],
    square: "#F6F6F6",
    brightness: 1,
  },
  dark: {
    primary: "#44C1A9",
    background: "#1A263A",
    black: "#09152A",
    text: "#ffffff",
    blackBlue: "#1A263A",
    blue: "#2732BF",
    orange: "#D8670C",
    white: "#FFFFFF",
    gray: "#ADADAD",
    gradients: [
      ["rgb(9, 21, 42)", "rgb(9, 21, 42)"],
      ["#FFFFFF", "#F6F6F6", "#E5E5E5"],
    ],
    square: "#F6F6F6",
    brightness: 1000,
  },
};
