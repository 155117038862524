import Providers from "./providers/providers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens";
import StudyCase from "./screens/studyCase";

const App = () => {
  return (
    <Providers>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/study-cases/:project" element={<StudyCase />} />
        </Routes>
      </BrowserRouter>
    </Providers>
  );
};

export default App;
