import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled from "styled-components";
import { useRef } from "react";

import semiCircle from "../../assets/images/semiCircle.svg";
import lomoIcon1 from "../../assets/images/lomoIcon1.svg";
import lomoIcon1Dark from "../../assets/images/lomoIcon1-dark.svg";
import lomoIcon2 from "../../assets/images/lomoIcon2.svg";
import lomoIcon2Dark from "../../assets/images/lomoIcon2-dark.svg";
import lomoIcon3 from "../../assets/images/lomoIcon3.svg";
import lomoIcon3Dark from "../../assets/images/lomoIcon3-dark.svg";
import lomoIcon4 from "../../assets/images/lomoIcon4.svg";
import lomoIcon4Dark from "../../assets/images/lomoIcon4-dark.svg";
import { useTheme } from "../../context/theme";

type Item = {
  image: string;
  title: string;
  subtitle?: string;
  description: string;
};

const ProcessMobile = () => {
  const processContainer: any = useRef();
  const { t } = useTranslation();
  const [theme] = useTheme();
  const items: Item[] = [
    {
      image: theme === "light" ? lomoIcon1 : lomoIcon1Dark,
      title: t("discovery"),
      description: t("paragraph5"),
    },
    {
      image: theme === "light" ? lomoIcon2 : lomoIcon2Dark,
      title: t("design"),
      description: t("paragraph4"),
    },
    {
      image: theme === "light" ? lomoIcon3 : lomoIcon3Dark,
      title: t("development"),
      description: t("paragraph6"),
    },
    {
      image: theme === "light" ? lomoIcon4 : lomoIcon4Dark,
      title: t("deployment"),
      subtitle: t("dreamsTrue"),
      description: t("paragraph7"),
    },
  ];


  // const handleObserv = (e: { isIntersecting: any }[]) => {
  //   if (e[0].isIntersecting) {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // };

  // useEffect(() => {
  //   const options = {
  //     rootMargin: "0px",
  //     threshold: 0.5,
  //   };
  //   const observer = new IntersectionObserver(handleObserv, options);
  //   observer.observe(processContainer.current);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   let ctx = gsap.context(() => {});
  //   if (isVisible) {
  //     ctx = gsap.context(() => {
  //       gsap.fromTo(
  //         ".item0",
  //         {
  //           rotateZ: -360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".item1",
  //         {
  //           rotateZ: 360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 1.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".item2",
  //         {
  //           rotateZ: -360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 3,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".item3",
  //         {
  //           rotateZ: 360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 4.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".img0",
  //         {
  //           rotateZ: -360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".img1",
  //         {
  //           rotateZ: 360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 1.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".img2",
  //         {
  //           rotateZ: -360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 3,
  //           repeatDelay: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".img3",
  //         {
  //           rotateZ: 360,
  //         },
  //         {
  //           rotateZ: 0,
  //           duration: 2.5,
  //           delay: 4.5,
  //           repeatDelay: 0,
  //         }
  //       );
  //     }, processContainer);
  //   }else{
  //     ctx.kill();
  //   }
  //   return () => ctx.kill();
  // }, [isVisible]);

  return (
    <Element name="#sec3">
      <Container>
        <SemiCircle src={semiCircle} />

        <Title>{t("thisIsHowWeHelpYou")}</Title>
        <Subtitle>{t("paragraph3")}</Subtitle>

        <ItemsContainer ref={processContainer}>
          {items.map(({ image, title, subtitle, description }, index) => (
            <ItemContainer key={title}>
              <BackgroundImage
                className={`item${index}`}
                even={index % 2 === 0}
              />
              <ItemImg className={`img${index}`} src={image} />

              <ItemTitleContainer>
                <ItemNumber>0{index + 1}</ItemNumber>
                <ItemTitle>{title}</ItemTitle>
              </ItemTitleContainer>

              {subtitle && <ItemSubtitle>{subtitle}</ItemSubtitle>}

              <ItemDescription even={index % 2 === 0}>
                {description}
              </ItemDescription>
            </ItemContainer>
          ))}
        </ItemsContainer>
      </Container>
    </Element>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  padding: 200px 16px 110px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 540px) {
    display: block;
    padding: 50px 16px 50px 16px;
  }
`;

const SemiCircle = styled.img`
  width: 250px;
  position: absolute;
  top: -80px;
  right: -18px;
  padding-top: 250px;
  @media (max-width: 480px) {
    display: none;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 12px;
  font-size: 65px;

  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
    width: 100%;
  }
`;

const Subtitle = styled.h2`
  color: #44c1a9;
  text-align: center;
  margin-bottom: 20px;
  font-size: 23px;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 30px;
    width: 100%;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  max-width: 1440px;
  padding: 0 120px;
  margin-top: 50px;
  z-index: 2;
  @media (max-width: 480px) {
    display: block;
    padding: 0;
  }
`;

const BackgroundImage = styled.div<{ even: boolean }>`
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;

  @media (max-width: 480px) {
    background-size: 300px;
    background-position: right top;
    background-position-y: 25px;
    height: auto;
    padding: 0;
    align-items: flex-start;
    margin: 20px 0 0 0;

    &:nth-child(even) {
      align-items: flex-end;
      background-position: left top;
      background-position-y: 25px;
    }
  }
`;

const ItemContainer = styled.div`
  height: 200px;
  width: 30%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
  @media (max-width: 480px) {
    height: auto;
    padding: 0;
    align-items: flex-start;
    margin: 20px 0 0 0;
    width: auto;

    &:nth-child(even) {
      align-items: flex-end;
    }
  }
`;

const ItemImg = styled.img`
  width: 45px;
  height: 45px;

  margin-bottom: 10px;

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const ItemNumber = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  font-size: 35px;
  font-weight: bold;
  margin-right: 10px;

  @media (max-width: 480px) {
    font-size: 45px;
  }
`;

const ItemTitle = styled.h3`
  color: ${({ theme }) => theme.palette.text};
  font-size: 25px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const ItemDescription = styled.span<{ even: boolean }>`
  color: #44c1a9;
  font-size: 15px;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 480px) {
    width: 80%;
    text-align: ${({ even }) => (even ? "left" : "right")};
    font-size: 18px;
  }
`;

const ItemSubtitle = styled.p`
  color: #44c1a9;
  font-size: 15px;
  text-align: center;
  line-height: 1.5;

  font-weight: bold;
`;

export default ProcessMobile;
