import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled from "styled-components";
import projectsjson from "../../assets/projects.json";
import { useNavigate } from "react-router-dom";

type ItemType = {
  title: string;
  subtitle: string;
  description: string;
  technologies: string[];
  devicesImage: string;
  project: string;
};

const StudyCases = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    const projectsArray: ItemType[] = [];
    const projects: any = projectsjson;
    for (const key in projects) {
      const newItem: ItemType = {
        title: projects?.[key]?.[language.slice(0,2)]?.title || "",
        subtitle: projects?.[key]?.[language.slice(0,2)]?.subtitle || "",
        description: projects?.[key]?.[language.slice(0,2)]?.subtitle || "",
        technologies: projects?.[key]?.[language.slice(0,2)]?.technologies || [],
        devicesImage: projects?.[key]?.[language.slice(0,2)]?.preview || "",
        project: projects?.[key]?.[language.slice(0,2)]?.projectKey || "",
      };
      projectsArray.push(newItem);
    }
    setItems(projectsArray);
  }, [language]);

  return (
    <Element name="#sec4">
      <Container>
        <Title>{t("studyCases")}</Title>
        <Subtitle>{t("studyCasesSubtitle")}</Subtitle>

        <ItemsContainer>
          {items.map(
            ({
              title,
              subtitle,
              technologies,
              description,
              devicesImage,
              project,
            }) => (
              <Item
                {...{
                  title,
                  subtitle,
                  technologies,
                  description,
                  devicesImage,
                  project,
                }}
                key={title}
              />
            )
          )}
        </ItemsContainer>
      </Container>
    </Element>
  );
};

const Item = ({
  title,
  subtitle,
  description,
  technologies,
  devicesImage,
  project,
}: ItemType) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const navigateToProject = (project: String) => {
    navigate(`/study-cases/${project}`);
  };

  return (
    <ItemContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ItemTitle>{title}</ItemTitle>

      {/* <ItemSubtitle>{subtitle}</ItemSubtitle> */}

      <ItemDescription>{description}</ItemDescription>

      {/* {technologies.map((tech: string) => (
        <ItemTechnology key={tech} src={`/technologies/${tech.toLowerCase()}.svg`} />
      ))} */}

      <ItemDevicesImg src={`/projects/${devicesImage}`} />

      <ItemButtonContainer {...{ hovered }}>
        <ItemButton onClick={() => navigateToProject(project)}>
          <ItemButtonText>{t("viewProject")}</ItemButtonText>
        </ItemButton>
      </ItemButtonContainer>
    </ItemContainer>
  );
};

const Container = styled.div`
  padding: 32px 16px 64px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  @media (max-width: 540px) {
    display: block;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 12px;
  font-size: 65px;

  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
    width: 100%;
  }
`;

const Subtitle = styled.h2`
  color: #44c1a9;
  text-align: center;
  margin-bottom: 20px;
  font-size: 23px;
  
  @media (max-width: 540px) {
    text-align: center;
    font-size: 30px;
    width: 100%;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 50px;
  max-width: 1440px;

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ItemContainer = styled.div`
  max-width: 33%;
  @media (max-width: 540px) {
    max-width: 90%;
  }
`;

const ItemTitle = styled.h3`
  font-size: 30px;
  color: ${({ theme }) => theme.palette.primary};
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 35px;
  }
`;

// const ItemSubtitle = styled.p`
//   color: ${({ theme }) => theme.palette.text};
//   font-size: 20px;
//   margin-bottom: 10px;

//   @media (max-width: 480px) {
//     font-size: 30px;
//   }
// `;

const ItemDescription = styled.p`
  color: ${({ theme }) => theme.palette.text};
  font-size: 15px;
  margin-bottom: 32px;
  max-width: 80%;

  @media (max-width: 480px) {
    font-size: 16px;
    max-width: 100%;
  }
`;

const ItemTechnology = styled.img`
  width: 25px;
  height: 25px;
  margin: 50px 15px 50px 0;

  @media (max-width: 480px) {
    width: 35px;
    height: 35px;
    margin: 10px 15px 10px 0;
  }
`;

const ItemDevicesImg = styled.img`
  width: 100%;
`;

const ItemButtonContainer = styled.div<{ hovered: boolean }>`
  transition: all 0.3s;
  opacity: ${({ hovered }) => (hovered ? 1 : 0)};
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;

  @media (max-width: 480px) {
    opacity: 1;
  }
`;

const ItemButton = styled.button`
  background-color: ${({ theme }) => theme.palette.text};
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 10px 50px -7px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ItemButtonText = styled.p`
  color: ${({ theme }) => theme.palette.background};
  text-transform: uppercase;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 19px;
  }
`;

export default StudyCases;
