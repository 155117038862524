import { useEffect, useRef, useState, useContext } from "react";
import { scrollContext } from "../../context/scrollContext";

import styled, { keyframes } from "styled-components";
import emailjs from "@emailjs/browser";

import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import logo from "../../assets/images/logo.svg";
import Loader from "../../components/Loader";

type InputsState = {
  name: string;
  email: string;
  company: string;
  role: string;
  project: string;
  policy: boolean;
  suscribed: boolean;
};

export default function ContSix() {
  const { t } = useTranslation();
  const firstContainer: any = useRef();
  const [progress, setProgress] = useState<number>(20);
  const [hightElement, setHightElement] = useState(0);
  const [positionToTop, setPositionToTop] = useState(0);
  const [sending, setSending] = useState(false);
  const {
    scroll: { scrollToTop },
  } = useContext(scrollContext);
  const [inputs, setInputs] = useState<InputsState>({
    name: "",
    email: "",
    company: "",
    role: "",
    project: "",
    policy: false,
    suscribed: false,
  });

  const [status, setStatus] = useState<null | "success" | "error">(null);

  function scrolling() {
    const start = positionToTop - 100;
    const end = positionToTop + hightElement;
    const scroll = scrollToTop;
    const progress = Math.floor(((scroll - start) / (end - start)) * 25);
    setProgress(progress);
  }

  useEffect(() => {
    if (firstContainer.current) {
      setHightElement(firstContainer.current.clientHeight);
      setPositionToTop(firstContainer.current.offsetTop);
    }
    if (hightElement & positionToTop) {
      scrolling();
    }
  }, [firstContainer, hightElement, positionToTop, scrollToTop]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendEmail = () => {
    // const { name, email, company, role, project } = inputs
    // const formValues = `name=${name}&email=${email}&company=${company}&role=${role}&project=${project}`
    setSending(true);
    emailjs
      .send("service_4wb3cke", "template_67t1747", inputs, "Q7hbjeeAa-3b_WD9D")
      .then(
        () => {
          setStatus("success");
          setSending(false);
          setInputs({
            name: "",
            email: "",
            company: "",
            role: "",
            project: "",
            policy: false,
            suscribed: false,
          });
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        },
        (error) => {
          console.log(error);
          setStatus("error");
          setSending(false);
          setInputs({
            name: "",
            email: "",
            company: "",
            role: "",
            project: "",
            policy: false,
            suscribed: false,
          });
          setTimeout(() => {
            setStatus(null);
          }, 4000);
        }
      );
  };

  return (
    <Element name="#sec8">
      <Container ref={firstContainer}>
        <SectionTitle>{t("readyToBuild")}</SectionTitle>
        <SubTitle>{t("letsFigure")}</SubTitle>
        <FormContainer>
          <InputContainer>
            <InputTitle>{t("form1")}</InputTitle>
            {/* <InputSubTitle>{t("form2")}</InputSubTitle> */}
            <Input
              type={"text"}
              placeholder={t("form3")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, name: e.target.value }))
              }
              value={inputs.name}
            />
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form4")}</InputTitle>
            <Input
              type={"email"}
              placeholder={t("form6")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, email: e.target.value }))
              }
              value={inputs.email}
            />
            <InputSubTitle>{t("form5")}</InputSubTitle>
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form7")}</InputTitle>
            <Input
              type={"text"}
              placeholder={t("form9")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, company: e.target.value }))
              }
              value={inputs.company}
            />
          </InputContainer>
          <InputContainer>
            <InputTitle>{t("form10")}</InputTitle>
            {/* <InputSubTitle>{t("form11")}</InputSubTitle> */}
            <Input
              type={"text"}
              placeholder={t("form12")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, role: e.target.value }))
              }
              value={inputs.role}
            />
            <InputSubTitle>{t("form8")}</InputSubTitle>
          </InputContainer>
          <InputContainer big>
            <InputTitle>{t("form13")}</InputTitle>
            {/* <InputSubTitle>{t("form14")}</InputSubTitle> */}
            <TextArea
              placeholder={t("form15")}
              onChange={(e) =>
                setInputs((inputs) => ({ ...inputs, project: e.target.value }))
              }
              value={inputs.project}
            />
          </InputContainer>
          <InputContainer big>
            <CustomCheckbox>
              <input
                type="checkbox"
                id="policy"
                name="policy"
                checked={inputs.policy}
                onChange={(e) =>
                  setInputs((inputs) => ({
                    ...inputs,
                    policy: e.target.checked,
                  }))
                }
              />
              <Checkbox
                onClick={() =>
                  setInputs((inputs) => ({
                    ...inputs,
                    policy: !inputs.policy,
                  }))
                }
                checked={inputs.policy}
              />
              <label htmlFor="policy">
                {t("form17")} <span>{t("form18")}</span>
              </label>
            </CustomCheckbox>
            <CustomCheckbox>
              <input
                type="checkbox"
                id="suscribed"
                name="suscribed"
                checked={inputs.suscribed}
                onChange={(e) =>
                  setInputs((inputs) => ({
                    ...inputs,
                    suscribed: e.target.checked,
                  }))
                }
              />
              <Checkbox
                onClick={() =>
                  setInputs((inputs) => ({
                    ...inputs,
                    suscribed: !inputs.suscribed,
                  }))
                }
                checked={inputs.suscribed}
              />
              <label htmlFor="suscribed">{t("form19")}</label>
            </CustomCheckbox>
          </InputContainer>
        </FormContainer>
        {!sending ? (
          <SendButton onClick={sendEmail}>{t("form16")}</SendButton>
        ) : (
          <SendButton>{<Loader />}</SendButton>
        )}

        <EmailStatus
          style={
            status === "success"
              ? { opacity: 1 }
              : status === "error"
              ? { opacity: 1 }
              : { opacity: 0, height: 0 }
          }
        >
          {status === "success"
            ? t("emailSuccess")
            : status === "error"
            ? t("emailError")
            : t("emailSuccess")}
        </EmailStatus>
        <Squares>
          <Li
            style={{
              bottom: `${progress + 80}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 15}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 75}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 95}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 50}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 40}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 70}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 20}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 60}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
        </Squares>
      </Container>
    </Element>
  );
}

const Container = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  padding: 50px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  font-size: 55px;
  text-align: center;
  z-index: 10;
  @media (max-width: 480px) {
    padding: 10px 0 30px 0;
  }
`;
const SendButton = styled.button`
  background-color: ${({ theme }) => theme.palette.blackBlue};
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  @media (max-width: 480px) {
    width: 100%;
  }
  z-index: 10;
  opacity: 1;
  transition: 0.3s;
`;
const SubTitle = styled.p`
  color: #44c1a9;
  font-size: 22px;
  margin: 16px 0;
  text-align: center;
  z-index: 10;
  @media (max-width: 480px) {
    font-size: 24px;

    padding: 0 40px 30px 40px;
  }
`;
const EmailStatus = styled.p`
  color: #44c1a9;
  font-size: 22px;
  margin: 20px 0;
  z-index: 10;
  @media (max-width: 480px) {
    font-size: 24px;

    padding: 0 40px 30px 40px;
  }

  transition: 0.3s;
`;
const FormContainer = styled.div`
  width: 100%;
  max-width: 820px;
  display: flex;
  flex: 1;
  margin-top: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;
const InputContainer = styled.div<{ big?: Boolean }>`
  width: ${(p) => (p.big ? "100%" : "46%")};
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const InputTitle = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: 16px;
  padding-bottom: 8px;
`;
const InputSubTitle = styled.label`
  font-size: 14px;
  color: #44c1a9;
  margin: 6px 0;
  font-style: italic;
`;
const Input = styled.input<{ big?: Boolean }>`
  height: 40px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.text};
  border: 1px solid lightgrey;
  padding: 0 8px;
  font-size: 14px;
  outline: none;
  padding: 2px 18px;
  ::placeholder {
    opacity: 0.7;
    color: ${({ theme }) => theme.palette.text};
  }
`;
const TextArea = styled.textarea`
  height: 200px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.text};
  border: none;
  padding: 8px;
  border: 1px solid lightgrey;
  resize: none;
  font-size: 14px;
  outline: none;
  padding: 18px 18px;
  ::placeholder {
    opacity: 0.7;
    color: ${({ theme }) => theme.palette.text};
  }
`;
const Animate = keyframes`
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0.3;
        border-radius: 20%;
    }`;

const Squares = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Li = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: none;
  /* animation: ${Animate} 25s linear infinite; */
  transform: rotate(0deg);
  transition: bottom 1s linear, transform 1s linear;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }

  :nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  :nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  :nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  :nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  :nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  :nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  :nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  :nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  :nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  :nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    :hover {
    }
  }
`;
const CustomCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  input {
    margin-right: 8px;
    cursor: pointer;
    display: none;
  }
  label {
    cursor: pointer;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text};
    span {
      color: #44c1a9;
    }
  }
`;
const Checkbox = styled.div<{ checked: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.text};
  background-color: ${({ theme, checked }) =>
    checked ? "#44c1a9" : "transparent"};
  transition: 0.3s;
  cursor: pointer;
  margin-right: 10px;
`;
