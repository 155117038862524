import styled from "styled-components";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";

SwiperCore.use([Pagination, Navigation]);

export default function ContFour() {
  const { t } = useTranslation();
  const langSelected = localStorage.getItem("i18nextLng") || "en";

  return (
    <Element name="#sec6">
      <Container>
        <SubContainerOne>
          <Title>{t("ourPerformanceWithCustomers")}</Title>
        </SubContainerOne>
        <SwiperWrapper
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={1000}
          navigation
          className="mySwiper"
        >
          {profiles.map((p) => (
            <SwiperSlide key={p?.name}>
              <SlideContainer>
                <Paragraph>
                  {langSelected === "es" ? p.description.es : p.description.en}
                </Paragraph>
                <ProfileCont>
                  <ProfileText>
                    <ProfileName>{p.name}</ProfileName>
                    <ProfileCompany>{p.company}</ProfileCompany>
                  </ProfileText>
                </ProfileCont>
              </SlideContainer>
            </SwiperSlide>
          ))}
        </SwiperWrapper>
      </Container>
    </Element>
  );
}

const profiles = [
  {
    name: "Javier Reolid",
    description: {
      es: '"Lömo nos ayudó a desarrollar un simulador acústico integrado en nuestro e-commerce y nuestra web. Con este simulador ayudamos a nuestros clientes a elegir los productos adecuados para su instalación. Sin duda el equipo de Lömo aportó grandes ideas que ayudaron mejorar el proyecto inicial, siendo el resultado muy satisfactorio. Además, tuvimos una excelente comunicación durante todo el proceso de desarrollo, ayudando así a alcanzar los objetivos programados."',
      en: '"Lömo helped us by modeling and developing an acoustic simulator to integrate into our website and e-commerce. With this simulator, we help our customers to choose the right products for their installation. Undoubtedly the Lömo team provided great ideas that helped improve the initial project, being the result very satisfactory. In addition, we had excellent communication throughout the whole development process, allowing us to achieve the programmed objectives."',
    },
    company: "AV Systems Product Manager - Equison",
  },
  {
    name: "Ignacio Rodríguez Aedo",
    description: {
      es: '"Decidimos trabajar con Lömo cuando observamos que tenían experiencia en el desarrollo de software en diversos campos. Nos ayudaron a construir nuestro servidor para la gestión de activos de alto valor. Desde la fase de inscripción de usuarios hasta la supervisión y actualización de nuestros dispositivos. Colaboramos con el equipo en la arquitectura, los protocolos y la topología para integrarlos. Sin duda, trabajar con Lömo fue un éxito. Porque, a pesar de su compromiso y proactividad, la parte técnica fue un gran valor para nuestra empresa. Esperamos seguir trabajando juntos durante mucho tiempo."',
      en: '"We decided to work with Lömo when we saw that they had experience in software development of various fields. They helped us build our server for high value asset management. From the user enrollment stage to the monitoring and updating of our devices. We collaborated with the team on the architecture, protocols and topology in order to integrate them. Certainly, working with Lömo was a success. Because, in spite of their commitment and proactivity, the technical part was a great value for our company. We expect to continue working together for a long time."',
    },
    company: "Co-Founder - Manglar Labs",
  },
  {
    name: "Daniela Castro",
    description: {
      es: '"Necesitábamos desarrollar una aplicación móvil para que expertos y no expertos en el sector energético pudieran consultar y ver datos energéticos. Lömo nos ayudó a entender los requisitos para crear y gestionar una aplicación en las diferentes tiendas. También nos ayudaron a delimitar nuestras necesidades, entender el objetivo del proyecto y brindar una experiencia digital. Nos sentimos acompañados en cada paso del camino e incluidos en cada etapa del desarrollo del proyecto. Así, pudimos lanzar END 100% operativa en iOS y Android en 6 meses. Fue genial trabajar con Lömo; los sentimos parte del equipo de AES para el desarrollo de END. Por lo tanto, esperamos volver a colaborar con ellos pronto."',
      en: '"We needed to develop a mobile application for experts and non-experts in the energy sector to consult and view energy data. Lömo helped us understand the requirements for creating and managing an app in the different app stores. They also helped us to delimit our needs, understand the objective of the project and provide a digital experience. We felt accompanied every step of the way and included in every stage of the project development. Thus, we were able to launch END 100% operational on iOS and Android in 6 months. It was great to work with Lömo; we felt them part of the AES team for the development of END. Therefore, we look forward to collaborating with them again soon."',
    },
    company: "Commercial New Business and Innovation Leader - AES México",
  },
  {
    name: "Federico Añaños",
    description: {
      es: '"Lömo nos ayudó a lanzar nuestra tienda online. Diseñaron y desarrollaron la solución y la desplegaron en la nube, así como su posterior lanzamiento y mantenimiento."',
      en: '"Lömo helped us launch our online store. They designed and developed the solution and deployed it on the cloud, and its subsequent launch and maintenance."',
    },
    company: "Project Leader - Basani",
  },
  {
    name: "Sabrina Migliorino",
    description: {
      es: '"Lömo nos ayudó a modelar y desarrollar una solución para automatizar los diferentes datos de radar que se distribuyen por el espacio aéreo argentino para obtener los mensajes aeronáuticos. Fue un trabajo duro que pudimos llevar a cabo gracias al trabajo en equipo. Sin duda, volveríamos a trabajar con ellos."',
      en: '"Lömo helped us model and develop a solution to automate the different radar data that are distributed throughout the Argentine airspace to obtain the aeronautical messages. It was hard work that we were able to carry out thanks to teamwork. We would certainly work with them again."',
    },
    company: "Head of Statistics - EANA",
  },
];

const Container = styled.div`
  padding: 100px 50px;
  display: flex;
  align-items: center;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.gradients[0][1]} 0%, ${theme.palette.background} 30%, ${theme.palette.background} 70%,  ${theme.palette.gradients[0][1]} 100%)`};
  @media (max-width: 540px) {
    flex-direction: column;
    padding: 50px 25px;
  }
`;
const SubContainerOne = styled.div`
  width: 50%;
  padding: 0 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 540px) {
    width: auto;
  }
`;
const SwiperWrapper = styled(Swiper)`
  width: 50%;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .swiper-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 540px) {
    min-width: 300px;
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #44c1a9;
  font-size: 55px;
  max-width: 440px;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
  }
`;
const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.text};
  font-size: 20px;
  text-align: justify;
  line-height: 30px;
  max-width: 450px;
  padding: 0 40px;
  @media (max-width: 480px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.5;
  }
`;
const ProfileCont = styled.div`
  display: flex;
  margin-top: 16px;
  padding: 0 40px;
  align-items: center;
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;
const ProfileName = styled.label`
  font-size: 22px;
  font-family: "Source Sans Pro Medium";
  color: #44c1a9;
  @media (max-width: 540px) {
    font-size: 22px;
  }
`;
const ProfileCompany = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: 18px;
  @media (max-width: 540px) {
    font-size: 18px;
  }
`;
const SlideContainer = styled.div`
  padding: 0 0px;
  width: 100%;
  height: 100%;
  @media (max-width: 540px) {
    padding: 0;
  }
`;
