import { createGlobalStyle } from "styled-components";

import SourceSansProLight from "../assets/fonts/SourceSansPro-Light.woff";
import SourceSansProItalic from "../assets/fonts/SourceSansPro-Italic.woff";
import SourceSansProRegular from "../assets/fonts/SourceSansPro-Regular.woff";
import SourceSansProSemiBold from "../assets/fonts/SourceSansPro-SemiBold.woff";
import SourceSansProBold from "../assets/fonts/SourceSansPro-Bold.woff";

const GlobalStyles = createGlobalStyle`
 @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: normal;
    src: url(${SourceSansProLight}) format('woff');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: normal;
    src: url(${SourceSansProItalic}) format('woff');
  }

  @font-face {
    font-family: 'Source Sans Pro Medium';
    font-style: normal;
    font-weight: normal;
    src: url(${SourceSansProRegular}) format('woff');
  }

  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 500;
    src: url(${SourceSansProRegular}) format('woff');
  }

  @font-face {
    font-family: 'Source Sans Pro Medium';
    font-style: normal;
    font-weight: 600;
    src: url(${SourceSansProSemiBold}) format('woff');
  }
  
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    src: url(${SourceSansProBold}) format('woff');
  } 

  *, html, body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro';
    font-weight: 500;
    scroll-behavior: smooth;
    transition: color 0.3s ease-in-out; 
    @media (max-width: 768px) {
      text-align: justify;
      -webkit-tap-highlight-color: transparent;

    }
  }

  .swiper {flex:1;}

  .swiper-slide {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .swiper-button-next,.swiper-button-prev{
    color: #D8670C;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: #D8670C88;
  }

  .swiper-pagination-bullet-active {
    background: #D8670C;
  }

  @media (max-width: 480px) {
    .swiper-button-next, .swiper-button-prev{
      top: unset;
      bottom: -0;
    }
  }

  .swiper-button-next {
    display: none;
  }
  
  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
  
  .swiper-button-prev::after {
    display: none;
  }

  .banner-typing-effect-cursor {
    font-family: Source Sans Pro;
    font-size: 50px;
    color: #44c1a9;
    font-weight: bold;
  }
  
  .banner-typing-effect-title {
    z-index: 1;
  }
`;

export default GlobalStyles;
