import styled from "styled-components";

import Header from "../components/Header";
import Footer from "../components/Footer";

import Hero from "./components/Hero";
import Solutions from "./components/Solutions";
// import StudyCases from "./components/StudyCases";
import Process from "./components/Process";
import TechStack from "./components/TechStack";
import Performance from "./components/Performance";
import Customers from "./components/Customers";
import Form from "./components/Form";
import useWindowScroll from "../hooks/useWindowScroll";
import StudyCases from "./components/StudyCases";
import { useEffect } from "react";
import useWindowSize from "../hooks/useWindowSize";
import ProcessMobile from "./components/ProcessMobile";

const Home = () => {
  useWindowScroll();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { width } = useWindowSize();

  return (
    <>
      <Header />
      <Container>
        <Hero />
      </Container>

      <Solutions />

      {width > 768 ? <Process /> : <ProcessMobile />}

      <TechStack />

      <Performance />

      <StudyCases />

      <Customers />

      <Form />

      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  max-width: 100vw;
  overflow-x: hidden;
`;

export default Home;
