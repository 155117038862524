import styled from "styled-components";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay } from "swiper";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import projectsjson from "../../assets/projects.json";
import { useState } from "react";
import { useParams } from "react-router-dom";

SwiperCore.use([Pagination, Navigation]);

export default function PerformanceProject() {
  const {
    i18n: { language },
  } = useTranslation();
  const { project }: any = useParams<{ project: string }>();
  const [projects, setprojects] = useState<any>(projectsjson); //eslint-disable-line
  const profiles = [
    {
      name: projects?.[project]?.[language]?.comments?.name,
      description: projects?.[project]?.[language]?.comments?.description,
      position: projects?.[project]?.[language]?.comments?.position,
    },
  ];

  return (
    <Element name="#sec6">
      <Container>
        {/* <SubContainerOne>
            <Title>{t("ourPerformanceWithCustomers")}</Title>
          </SubContainerOne> */}
        <SwiperWrapper
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={1000}
          navigation
          className="mySwiper"
        >
          {profiles.map((p) => (
            <SwiperSlide key={p?.name}>
              <SlideContainer>
                <Paragraph>{p.description}</Paragraph>
                <ProfileCont>
                  <ProfileText>
                    <ProfileName>{p.name}</ProfileName>
                    <ProfileCompany>{p.position}</ProfileCompany>
                  </ProfileText>
                </ProfileCont>
              </SlideContainer>
            </SwiperSlide>
          ))}
        </SwiperWrapper>
      </Container>
    </Element>
  );
}

const Container = styled.div`
  padding: 30px 50px;
  display: flex;
  align-items: center;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.gradients[0][1]} 0%,${theme.palette.gradients[0][1]} 100%)`};
  @media (max-width: 540px) {
    flex-direction: column;
    padding: 50px 25px;
  }
`;
const SubContainerOne = styled.div`
  width: 50%;
  padding: 0 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 540px) {
    width: auto;
  }
`;
const SwiperWrapper = styled(Swiper)`
  width: 70%;
  min-width: 320px;
  padding-bottom: 80px;
  @media (max-width: 540px) {
    min-width: 300px;
    width: 100%;
  }
`;
const Title = styled.h2`
  color: #44c1a9;
  font-size: 55px;
  max-width: 440px;
  @media (max-width: 540px) {
    text-align: left;
    font-size: 40px;
  }
`;
const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.text};
  font-size: 20px;
  padding: 0 40px;
  max-width: 600px;
  text-align: justify;
  line-height: 30px;

  @media (max-width: 480px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.5;
  }
`;
const ProfileCont = styled.div`
  display: flex;
  margin-top: 18px;
  padding: 0 40px;
  align-items: center;
  @media (max-width: 480px) {
    padding: 0 16px;
  }
`;
const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;
const ProfileName = styled.label`
  font-size: 22px;
  font-family: "Source Sans Pro Medium";
  color: #44c1a9;
  @media (max-width: 540px) {
    font-size: 22px;
  }
`;
const ProfileCompany = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: 18px;
  @media (max-width: 540px) {
    font-size: 18px;
  }
`;
const SlideContainer = styled.div`
  padding: 0 50px;
  @media (max-width: 540px) {
    padding: 0;
  }
`;
