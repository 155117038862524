import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import projectsjson from "../../assets/projects.json";
import logo from "../../assets/images/logo.svg";

const HeroProject = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { project }: any = useParams<{ project: string }>();
  const navigate = useNavigate();
  const [projects, setprojects] = useState<any>(projectsjson); //eslint-disable-line

  return (
    <Container id="#sec1">
      <FirstColumn>
        <Breadcrumb>
          <BreadcrumbItem
            style={{
              cursor: "pointer",
              color: "#44C1A9",
            }}
            onClick={() => navigate("/")}
          >
            {t("home")}
          </BreadcrumbItem>
          <BreadcrumbItem>
            {">"}
          </BreadcrumbItem>
          <BreadcrumbItem>
            {projects?.[project]?.[language.slice(0, 2)]?.title}
          </BreadcrumbItem>
        </Breadcrumb>
        <SubContainer className="container">
          <ImageIcon
            src={`/projects/${
              projects?.[project]?.[language.slice(0, 2)]?.icon
            }`}
          />
          <Title>{projects?.[project]?.[language.slice(0, 2)]?.title}</Title>
          <SubTitle>
            {projects?.[project]?.[language.slice(0, 2)]?.subtitle}
          </SubTitle>
          <ClientDetails>
            <Client>
              <ClientTitle>{t("clientStudyCase")}</ClientTitle>
              <ClientName>
                {projects?.[project]?.[language.slice(0, 2)]?.client}
              </ClientName>
            </Client>
            <Industry>
              <IndustryTitle>{t("industryStudyCase")}</IndustryTitle>
              <IndustryName>
                {projects?.[project]?.[language.slice(0, 2)]?.industry}
              </IndustryName>
            </Industry>
          </ClientDetails>

          {/* <Technologies>
            <TechnologyTitle>{t("technologiesStudyCase")}</TechnologyTitle>
            <TechnologiesContainer>
              {projects?.[project]?.[language.slice(0,2)]?.technologies?.map(
                (tech: string) => (
                  <TechnologyContainer>
                    <Technology>
                      <TechImage
                        src={`/technologies/${tech.toLowerCase()}.svg`}
                      />
                      <TechTitle>{tech}</TechTitle>
                    </Technology>
                  </TechnologyContainer>
                )
              )}
            </TechnologiesContainer>
          </Technologies> */}
        </SubContainer>
      </FirstColumn>
      <SecondColumn>
        <Logo src={logo} alt="logo" />
        <Devices
          className="item"
          src={`/projects/${
            projects?.[project]?.[language.slice(0, 2)]?.image
          }`}
        />
      </SecondColumn>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 100vw;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.background} 0%, ${theme.palette.background} 60%,  ${theme.palette.gradients[0][1]} 100%)`};
  padding: 64px 16px 64px 16px;
  @media (max-width: 540px) {
    flex-direction: column;
    padding: 0 16px;
    width: 90%;
  }
`;

const FirstColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 40%;
  height: 100%;
  max-width: 560px;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const SecondColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 60%;
  height: 100%;
  max-width: 840px;
  @media (max-width: 540px) {
    width: 90%;
  }
`;
const ImageIcon = styled.img`
  position: relative;
  width: 100%;
  max-width: 160px;
  z-index: 1;
  margin-bottom: 20px;
`;
const RollIn = keyframes`
  0% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
`;

const Logo = styled.img`
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  -webkit-animation: ${RollIn} 2.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  animation: ${RollIn} 2.5s cubic-bezier(0.23, 1, 0.32, 1) both;
`;

const SubContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  z-index: 1;
  min-height: 358px;

  @media (max-width: 540px) {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    min-height: auto;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text};
  font-family: Source Sans Pro;
  font-weight: 500;
  font-size: 60px;
  line-height: 90px;

  @media (max-width: 480px) {
    font-size: 46px;
    text-align: left;
    word-spacing: 20px;
    line-height: 46px;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  font-family: Source Sans Pro;
  font-weight: 500;
  font-size: 23px;
  line-height: 24px;
  letter-spacing: 0.05em;
  max-width: 600px;
  width: 100%;
  @media (max-width: 480px) {
    text-align: left;
    font-size: 16px;
  }
`;

const Devices = styled.img`
  position: absolute;
  width: 90%;
  right: 50px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 1;

  @media (max-width: 728px) {
    width: 100%;
    position: relative;
    right: 0;
    bottom: 20px;
  }
`;

const ClientDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 64px;
  max-width: 800px;
  width: 100%;
  @media (max-width: 728px) {
    flex-direction: column;
    margin-top: 32px;
  }
`;

const Client = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  gap: 10px;
  @media (max-width: 728px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ClientTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #adadad;
  @media (max-width: 728px) {
    font-size: 16px;
  }
`;

const ClientName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text};
  @media (max-width: 728px) {
    font-size: 16px;
  }
`;

const Industry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  gap: 10px;
  @media (max-width: 728px) {
    width: 100%;
  }
`;

const IndustryTitle = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #adadad;
  @media (max-width: 728px) {
    font-size: 16px;
  }
`;

const IndustryName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text};
  @media (max-width: 728px) {
    font-size: 16px;
  }
`;
const Breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  @media (max-width: 728px) {
  }
`;
const BreadcrumbItem = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.text};
  @media (max-width: 728px) {
    font-size: 16px;
  }
`;

export default HeroProject;
