import styled from 'styled-components'
import logoWhiteImg from '../assets/images/logo-black.svg'
import logo from '../assets/images/logo.png'
import argentina from '../assets/images/argentina.png'
import mexico from '../assets/images/mexico.png'
import chile from '../assets/images/chile.png'
import usa from '../assets/images/usa.png'
import es from '../assets/images/es.png'
import useWindowSize from '../hooks/useWindowSize'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const windowSize = useWindowSize()

  const { t } = useTranslation()

  return (
    <Container>
      <FlexCont>
        {windowSize.width > 1024 ? (
          <FooterLogo src={logoWhiteImg} />
        ) : (
          <FooterLogo big src={logo} />
        )}
      </FlexCont>

      <SubTitle>{t('weAreLocated')}</SubTitle>

      {windowSize.width > 540 ? (
        <>
          {countries.map((p) => (
            <CountryContainer key={p?.abbreviation}>
              <CountryFlag>
                <FlagImg src={p.img} />
              </CountryFlag>
              <CountryText>
                <FlagText>{p.abbreviation}</FlagText>
                <LabelRow>{p.address}</LabelRow>
              </CountryText>
            </CountryContainer>
          ))}
        </>
      ) : (
        <CountriesContainer>
          {countries.map((p) => (
            <CountryContainer key={p?.abbreviation}>
              <CountryFlag>
                <FlagImg src={p.img} />
              </CountryFlag>
              <CountryText>
                <FlagText>{p.abbreviation}</FlagText>
                <LabelRow>{p.address}</LabelRow>
              </CountryText>
            </CountryContainer>
          ))}
        </CountriesContainer>
      )}
      <FlexCont />
    </Container>
  )
}

const countries = [
  {
    img: es,
    abbreviation: 'ES',
    address: 'Málaga',
  },
  {
    img: usa,
    abbreviation: 'US',
    address: 'Miami',
  },
  {
    img: argentina,
    abbreviation: 'AR',
    address: 'Capital Federal',
  },
  {
    img: chile,
    abbreviation: 'CL',
    address: 'Santiago',
  },
  {
    img: mexico,
    abbreviation: 'MX',
    address: 'Distrito Federal',
  },
]

const Container = styled.div`
    background-image: ${({ theme }) =>
    `linear-gradient(to top, ${theme.palette.background} 0%, ${theme.palette.background} 60%,  ${theme.palette.gradients[0][1]} 100%)`};
  padding: 16px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
const SubTitle = styled.p`
  color: #44c1a9;
  font-size: 22px;
  margin: 36px 0;
  margin-right: 50px;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 24px;
    margin: 0;
    padding: 0 40px 30px 40px;
  }
`
const FooterLogo = styled.img<{ big?: Boolean }>`
  filter: ${({ theme }) =>
    theme.selected === 'light' ? 'invert(0)' : 'invert(100%)'};
  width: 135px;
  height: ${(p) => (p.big ? '60px' : '40px')};
  object-fit: contain;
  margin: 16px 0;
`
const FlexCont = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
const CountriesContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const CountryContainer = styled.div`
  display: flex;
  padding: 8px;
  margin: 0 25px;
  @media (max-width: 480px) {
    display: block;
    justify-content: center;
    align-items: center;
    margin: 5px 25px;
    width: 98px;
  }
`
const FlagImg = styled.img`
  width: 30px;
  height: 30px;
`
const FlagText = styled.label`
  color: #44c1a9;
  font-size: 15px;
  font-family: 'Source Sans Pro Medium';
`
const CountryFlag = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`
const CountryText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: #44c1a9;

  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-top: 10px;
  }
`
const LabelRow = styled.label`
  color: #44c1a9;
  font-size: 14px;
`
