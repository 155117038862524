import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useLayoutEffect, useRef } from "react";
import lomoIcon1 from "../../assets/images/lomoIcon1Complete.svg";
import lomoIcon1Dark from "../../assets/images/lomoIcon1-darkComplete.svg";
import lomoIcon2 from "../../assets/images/lomoIcon2Complete.svg";
import lomoIcon2Dark from "../../assets/images/lomoIcon2-darkComplete.svg";
import lomoIcon3 from "../../assets/images/lomoIcon3Complete.svg";
import lomoIcon3Dark from "../../assets/images/lomoIcon3-darkComplete.svg";
import lomoIcon4 from "../../assets/images/lomoIcon4Complete.svg";
import lomoIcon4Dark from "../../assets/images/lomoIcon4-darkComplete.svg";
import { useTheme } from "../../context/theme";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Element } from "react-scroll";

type Item = {
  image: string;
  title: string;
  subtitle?: string;
  description: string;
};

const Process = () => {
  const verticalsContainer: any = useRef();
  const firstItem: any = useRef();
  const secondItem: any = useRef();
  const thirdItem: any = useRef();
  const fourthItem: any = useRef();
  const secondText: any = useRef();
  const thirdText: any = useRef();
  const fourthText: any = useRef();
  const firstLogo: any = useRef();
  const secondLogo: any = useRef();
  const thirdLogo: any = useRef();
  const fourthLogo: any = useRef();
  const { t } = useTranslation();
  const [theme] = useTheme();
  const items: Item[] = [
    {
      image: theme === "light" ? lomoIcon1 : lomoIcon1Dark,
      title: t("discovery"),
      description: t("endDiscovery"),
    },
    {
      image: theme === "light" ? lomoIcon2 : lomoIcon2Dark,
      title: t("design"),
      description: t("endDesign"),
    },
    {
      image: theme === "light" ? lomoIcon3 : lomoIcon3Dark,
      title: t("development"),
      description: t("endDevelopment"),
    },
    {
      image: theme === "light" ? lomoIcon4 : lomoIcon4Dark,
      title: t("deployment"),
      subtitle: t("dreamsTrue"),
      description: t("endDeployment"),
    },
  ];
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: verticalsContainer.current,
      scrub: true,
      start: "top top",
      end: "+=200%",
      id: "pinVerticals",
      pinnedContainer: "#verticals",

      onUpdate: function (self) {
        let scrollPos = self.progress;
        if (scrollPos === 0) {
          firstItem.current.style.opacity = 1;
          secondItem.current.style.opacity = 0;
          firstLogo.current.style.opacity = 1;
          secondLogo.current.style.opacity = 0;
        } else if (scrollPos > 0 && scrollPos < 0.1) {
        } else if (scrollPos > 0.1 && scrollPos < 0.2) {
          fadeOutContainer(firstItem, scrollPos - 0.1);
          fadeOutLogo(firstLogo, scrollPos - 0.1);
          fadeInLogo(secondLogo, scrollPos - 0.1);
        } else if (scrollPos > 0.2 && scrollPos < 0.25) {
          fadeOutLogo(firstLogo, scrollPos - 0.1);
          fadeInLogo(secondLogo, scrollPos - 0.1);
          firstItem.current.style.opacity = 0;
          secondItem.current.style.opacity = 0;
        } else if (scrollPos > 0.25 && scrollPos < 0.35) {
          fadeInSecondContainer(secondItem, secondText, scrollPos - 0.25);
          fadeOutLogo(firstLogo, scrollPos - 0.1);
          fadeInLogo(secondLogo, scrollPos - 0.1);
        } else if (scrollPos > 0.35 && scrollPos < 0.4) {
          secondItem.current.style.opacity = 1;
        } else if (scrollPos > 0.4 && scrollPos < 0.5) {
          fadeOutContainer(secondItem, scrollPos - 0.4);
          fadeOutLogo(secondLogo, scrollPos - 0.4);
          fadeInLogo(thirdLogo, scrollPos - 0.4);
        } else if (scrollPos > 0.5 && scrollPos < 0.55) {
          secondItem.current.style.opacity = 0;
          fadeOutLogo(secondLogo, scrollPos - 0.4);
          fadeInLogo(thirdLogo, scrollPos - 0.4);
        } else if (scrollPos > 0.55 && scrollPos < 0.65) {
          fadeInSecondContainer(thirdItem, thirdText, scrollPos - 0.55);
          fadeOutLogo(secondLogo, scrollPos - 0.4);
          fadeInLogo(thirdLogo, scrollPos - 0.4);
        } else if (scrollPos > 0.65 && scrollPos < 0.7) {
          thirdItem.current.style.opacity = 1;
        } else if (scrollPos > 0.7 && scrollPos < 0.8) {
          fadeOutContainer(thirdItem, scrollPos - 0.7);
          fadeOutLogo(thirdLogo, scrollPos - 0.7);
          fadeInLogo(fourthLogo, scrollPos - 0.7);
        } else if (scrollPos > 0.8 && scrollPos < 0.85) {
          thirdItem.current.style.opacity = 0;
          fadeOutLogo(thirdLogo, scrollPos - 0.7);
          fadeInLogo(fourthLogo, scrollPos - 0.7);
        } else if (scrollPos > 0.85 && scrollPos < 0.95) {
          fadeInSecondContainer(fourthItem, fourthText, scrollPos - 0.85);
          fadeOutLogo(thirdLogo, scrollPos - 0.7);
          fadeInLogo(fourthLogo, scrollPos - 0.7);
        } else if (scrollPos > 0.95 && scrollPos < 0.1) {
          fourthItem.current.style.opacity = 1;
          fourthLogo.current.style.opacity = 1;
        } else {
        }
        if (scrollPos < 0.1) {
          firstItem.current.style.opacity = 1;
          secondLogo.current.style.opacity = 0;
        }
        if (scrollPos >= 0.2) {
          firstItem.current.style.opacity = 0;
        }
        if (scrollPos < 0.25) {
          secondItem.current.style.opacity = 0;
        }
        if (scrollPos >= 0.5) {
          secondItem.current.style.opacity = 0;
        }
        if (scrollPos < 0.55) {
          thirdItem.current.style.opacity = 0;
        }
        if (scrollPos >= 0.8) {
          thirdItem.current.style.opacity = 0;
        }
        if (scrollPos < 0.85) {
          fourthItem.current.style.opacity = 0;
        }
        if (scrollPos >= 0.55) {
          firstLogo.current.style.opacity = 0;
        }
        if (scrollPos >= 0.35) {
          firstLogo.current.style.opacity = 0;
        }
        if (scrollPos < 0.4) {
          thirdLogo.current.style.opacity = 0;
        }
        if (scrollPos >= 0.65) {
          secondLogo.current.style.opacity = 0;
        }
        if (scrollPos < 0.7) {
          fourthLogo.current.style.opacity = 0;
        }
        if (scrollPos >= 0.95) {
          thirdLogo.current.style.opacity = 0;
        }
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function fadeOutContainer(container: any, progress: number) {
    container.current.style.opacity = 1 - progress * 10;
  }

  function fadeInSecondContainer(
    container: any,
    secondContainer: any,
    progress: number
  ) {
    container.current.style.opacity = progress * 10;
    container.current.style.transform = `translateY(calc(20% - ${progress * 200
      }%))`;
    secondContainer.current.style.transform = `scale(calc(0.7 + ${progress * 3
      }))`;
  }
  function fadeOutLogo(container: any, progress: number) {
    container.current.style.opacity = 1 - progress * 4;
  }

  function fadeInLogo(container: any, progress: number) {
    container.current.style.opacity = progress * 4;
  }

  return (
    <Element name="#sec3">
      <Container
        ref={verticalsContainer}
        id="verticals"
        onScroll={(e) => console.log(e)}
      >
        <ScrollContainer>
          <BackGroundGradient />
          <TitleContainer>
            <Title>{t("thisIsHowWeHelpYou")}</Title>
            <Subtitle>{t("paragraph3")}</Subtitle>
          </TitleContainer>
          <ItemsContainer>
            <FirstItem ref={firstItem}>
              <ContentContainer>
                <FirstTitle>{items[0].title}</FirstTitle>
                <FirstDescription>{items[0].description}</FirstDescription>
              </ContentContainer>
            </FirstItem>
            <SecondItem ref={secondItem}>
              <ContentSecondContainer ref={secondText}>
                <FirstTitle>{items[1].title}</FirstTitle>
                <FirstDescription>{items[1].description}</FirstDescription>
              </ContentSecondContainer>
            </SecondItem>
            <SecondItem ref={thirdItem}>
              <ContentSecondContainer ref={thirdText}>
                <FirstTitle>{items[2].title}</FirstTitle>
                <FirstDescription>{items[2].description}</FirstDescription>
              </ContentSecondContainer>
            </SecondItem>
            <SecondItem ref={fourthItem}>
              <ContentSecondContainer ref={fourthText}>
                <FirstTitle>{items[3].title}</FirstTitle>
                <FirstDescription>{items[3].description}</FirstDescription>
              </ContentSecondContainer>
            </SecondItem>
            <FirstLogo src={items[0].image} ref={firstLogo} />
            <SecondLogo src={items[1].image} ref={secondLogo} />
            <SecondLogo src={items[2].image} ref={thirdLogo} />
            <SecondLogo src={items[3].image} ref={fourthLogo} />
          </ItemsContainer>
        </ScrollContainer>
      </Container>
    </Element>
  );
};
const ScrollContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  min-height: 100vh;
  overflow: hidden;
`;
const BackGroundGradient = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.gradients[0][1]} 0%, ${theme.palette.background} 30%, ${theme.palette.background} 70%,  ${theme.palette.gradients[0][1]} 100%)`};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 300vh;
  @media (max-width: 540px) {
    display: block;
    padding: 50px 16px 50px 16px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  z-index: 2;
  position: absolute;
  flex-direction: column;
  gap: 12px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 10%;
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  font-size: 65px;
  @media (max-width: 540px) {
    text-align: left;
    font-size: 40px;
    width: 60%;
  }
`;

const Subtitle = styled.label`
  color: #44c1a9;
  text-align: center;
  font-size: 23px;
  @media (max-width: 540px) {
    text-align: left;
    font-size: 30px;
    width: 60%;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 15%;
  @media (max-width: 480px) {
    display: block;
    padding: 0;
  }
`;
const FirstItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  height: 80vh;
  position: absolute;
  opacity: 1;
  transition: all 0.1s ease;
  @media (max-width: 480px) {
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
  }
`;
const SecondItem = styled(FirstItem)`
  opacity: 0;
  transform: translateY(20%);
`;
const FirstLogo = styled.img`
  width: 250px;
  height: 250px;
  position: absolute;
  right: 15%;
  transition: all 0.1s ease;
  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;
const SecondLogo = styled(FirstLogo)`
  opacity: 0;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  @media (max-width: 480px) {
    margin-left: 0;
  }
`;
const ContentSecondContainer = styled(ContentContainer)`
  transform: scale(0.7);
  transition: all 0.1s ease;
`;
const FirstTitle = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: start;
  font-size: 65px;
  width: 100%;
  @media (max-width: 480px) {
    text-align: left;
    font-size: 40px;
  }
`;
const FirstDescription = styled.label`
  color: #44c1a9;
  text-align: center;
  font-size: 23px;
  text-align: justify;
  @media (max-width: 480px) {
    text-align: left;
    font-size: 30px;
  }
`;
const BackgroundImage = styled.div<{ even: boolean }>`
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;

  @media (max-width: 480px) {
    background-size: 300px;
    background-position: right top;
    background-position-y: 25px;
    height: auto;
    padding: 0;
    align-items: flex-start;
    margin: 20px 0 0 0;

    &:nth-child(even) {
      align-items: flex-end;
      background-position: left top;
      background-position-y: 25px;
    }
  }
`;

const ItemContainer = styled.div`
  height: 200px;
  width: 30%;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
  @media (max-width: 480px) {
    height: auto;
    padding: 0;
    align-items: flex-start;
    margin: 20px 0 0 0;
    width: auto;

    &:nth-child(even) {
      align-items: flex-end;
    }
  }
`;

const ItemImg = styled.img`
  width: 45px;
  height: 45px;

  margin-bottom: 10px;

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const ItemNumber = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  font-size: 35px;
  font-weight: bold;
  margin-right: 10px;

  @media (max-width: 480px) {
    font-size: 45px;
  }
`;

const ItemTitle = styled.h3`
  color: ${({ theme }) => theme.palette.text};
  font-size: 25px;

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const ItemDescription = styled.span<{ even: boolean }>`
  color: #44c1a9;
  font-size: 15px;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 480px) {
    width: 80%;
    text-align: ${({ even }) => (even ? "left" : "right")};
    font-size: 16px;
  }
`;

const ItemSubtitle = styled.p`
  color: #44c1a9;
  font-size: 15px;
  text-align: center;
  line-height: 1.5;

  font-weight: bold;
`;

export default Process;
