import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState, useContext } from "react";
import { scrollContext } from "../../context/scrollContext";
import logo from "../../assets/images/logo.svg";
import projectsjson from "../../assets/projects.json";
import { useParams } from "react-router-dom";

const FeaturesProject = () => {
  const featuresContainer: any = useRef();
  const [progress, setProgress] = useState<number>(20);
  const [hightElement, setHightElement] = useState(0);
  const [positionToTop, setPositionToTop] = useState(0);
  const {
    scroll: { scrollToTop },
  } = useContext(scrollContext);
  const { t } = useTranslation();
  const { project }: any = useParams<{ project: string }>();
  const [projects, setprojects] = useState<any>(projectsjson);//eslint-disable-line

  function scrolling() {
    const start = positionToTop - 300;
    const end = positionToTop + hightElement;
    const scroll = scrollToTop;
    const progress = Math.floor(((scroll - start) / (end - start)) * 25);
    setProgress(progress);
  }

  useEffect(() => {
    if (featuresContainer.current) {
      setHightElement(featuresContainer.current.clientHeight);
      setPositionToTop(featuresContainer.current.offsetTop);
    }
    if (hightElement & positionToTop) {
      scrolling();
    }
  }, [featuresContainer, hightElement, positionToTop, scrollToTop]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Element name="#sec2" style={{ minHeight: "30vh" }}>
      <Container ref={featuresContainer}>
        <Title>{t("studyMenu2")}</Title>
        <Squares>
          <Li
            style={{
              bottom: `${progress + 80}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 15}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 75}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 95}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 50}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 40}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 70}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 20}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
          <Li
            style={{
              bottom: `${progress + 60}%`,
              transform: `rotate(${
                progress ? Math.floor((progress * 720) / 100) : 0
              }deg)`,
            }}
          >
            <img src={logo} alt="logo" />
          </Li>
        </Squares>
      </Container>
      <ImageFeaturesContainer>
        <DevicesImage
          src={`/projects/${projects?.[project]?.es?.features}`}
          alt="Features"
        />
      </ImageFeaturesContainer>
    </Element>
  );
};

const Container = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  padding: 16px;
  padding-top: 40px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  min-height: 55vh;
  @media (max-width: 540px) {
    padding: 16px 10px;
    min-height: 20vh;
  }
`;

const ImageFeaturesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: -40vh;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  @media (max-width: 540px) {
    padding: 8px 10px;
    margin-top: -10vh;
    width: auto;
  }
`;

const DevicesImage = styled.img`
  max-width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 12px;
  font-size: 65px;
  z-index: 10;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
    margin: 10px 0 10px 10px;
    width: 100%;
  }
`;

const Animate = keyframes`
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(360deg);
        opacity: 0;
        border-radius: 50%;
    }`;

const Squares = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Li = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: none;
  /* animation: ${Animate} 25s linear infinite; */
  transform: rotate(0deg);
  transition: bottom 1s linear, transform 1s linear;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  :nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  :nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  :nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  :nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
    @media (max-width: 540px) {
      display: none;
    }
  }
  :nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  :nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  :nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
    @media (max-width: 540px) {
      display: none;
    }
  }
  :nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  :nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  :nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    @media (max-width: 540px) {
      display: none;
    }
    :hover {
    }
  }
`;

export default FeaturesProject;
