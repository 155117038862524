import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState, useContext } from "react";
import { scrollContext } from "../../context/scrollContext";

import gsap from "gsap";

import useWindowSize from "../../hooks/useWindowSize";
import logo from "../../assets/images/logo.svg";
import App1Icon from "../../assets/images/appICon.png";
import ConexionIcon from "../../assets/images/dataIcon.png";
import GraphIcon from "../../assets/images/iotIcon.png";
import IAIcon from "../../assets/images/iaIcon.png";
import { useTheme } from "../../context/theme";

type Item = {
  id: number;
  title: string;
  subtitle?: string;
  description: string;
  icon: string;
  icon2?: string;
  cardDescription?: string;
};

const Solutions = () => {
  const itemsContainer: any = useRef();
  const weDoContainer: any = useRef();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(20);
  const [hightElement, setHightElement] = useState(0);
  const [positionToTop, setPositionToTop] = useState(0);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  const {
    scroll: { scrollToTop },
  } = useContext(scrollContext);
  const { t } = useTranslation();
  const [theme, setTheme] = useTheme();
  const [cardSelected, setCardSelected] = useState<number>(0);
  const items: Item[] = [
    {
      id: 1,
      title: t("box4Title"),
      subtitle: t("box4Subtitle"),
      description: t("box4Description"),
      icon: IAIcon,
      cardDescription: t("box4CardDescription"),
    },
    {
      id: 2,
      title: t("box1Title"),
      subtitle: t("box1Subtitle"),
      description: t("box1Description"),
      icon: App1Icon,
      cardDescription: t("box1CardDescription"),
    },
    {
      id: 3,
      title: t("box2Title"),
      subtitle: t("box2Subtitle"),
      description: t("box2Description"),
      icon: ConexionIcon,
      cardDescription: t("box2CardDescription"),
    },
    {
      id: 4,
      title: t("box3Title"),
      subtitle: t("box3Subtitle"),
      description: t("box3Description"),
      icon: GraphIcon,
      cardDescription: t("box3CardDescription"),
    },
  ];

  const handleObserv = (e: { isIntersecting: any }[]) => {
    if (e[0].isIntersecting) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  function scrolling() {
    const start = positionToTop - 100;
    const end = positionToTop + hightElement;
    const scroll = scrollToTop;
    const progress = Math.floor(((scroll - start) / (end - start)) * 25);
    setProgress(progress);
  }

  useEffect(() => {
    if (weDoContainer.current) {
      setHightElement(weDoContainer.current.clientHeight);
      setPositionToTop(weDoContainer.current.offsetTop);
    }
    if (hightElement & positionToTop) {
      scrolling();
    }
  }, [weDoContainer, hightElement, positionToTop, scrollToTop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: isMobile ? 0.1 : 0.7,
    };
    const observer = new IntersectionObserver(handleObserv, options);
    observer.observe(itemsContainer.current);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let ctx = gsap.context(() => {});
    if (isVisible && firstRender) {
      setFirstRender(false);
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".item",
          {
            opacity: 0,
            y: 100,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1.5,
            stagger: 0.5,
          }
        );
      }, itemsContainer);
    } else if (!isVisible && firstRender) {
      ctx = gsap.context(() => {
        gsap.fromTo(
          ".item",
          {
            opacity: 1,
          },
          {
            opacity: 0,
          }
        );
      }, itemsContainer);
    }
    return () => ctx.revert();
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Element name="#sec2">
        <Container ref={weDoContainer}>
          <Title>{t("whatWeDo")}</Title>
          <TextsContainer>
            {<Subtitle>{t("paragraph1")}</Subtitle>}
          </TextsContainer>

          <ItemsContainer ref={itemsContainer}>
            {items.map(
              ({
                id,
                title,
                subtitle,
                description,
                icon,
                icon2,
                cardDescription,
              }: Item) => (
                <ItemContainer
                  key={id}
                  onClick={() => setCardSelected(id)}
                  className={
                    cardSelected === id ? "item selected" : "item card"
                  }
                >
                  {cardSelected === id ? (
                    <>
                      <ItemTitle className="title">{title}</ItemTitle>
                      <BottomContainer>
                        <ItemTitle className="selected">
                          <img src={icon} alt="icon" />
                          {cardDescription}
                        </ItemTitle>
                      </BottomContainer>
                    </>
                  ) : (
                    <>
                      <ItemTextsContainer>
                        <ItemTitle>{title}</ItemTitle>

                        {subtitle ? (
                          <ItemSubtitle>{subtitle}</ItemSubtitle>
                        ) : null}

                        <ItemDescription>{description}</ItemDescription>
                      </ItemTextsContainer>

                      <ItemIconContainer>
                        <ItemIcon
                          src={icon}
                          style={{
                            filter:
                              theme === "dark"
                                ? "grayscale(1) brightness(3) opacity(0.9)"
                                : "",
                          }}
                        />
                        {icon2 && (
                          <ItemIcon
                            src={icon2}
                            style={{
                              filter:
                                theme === "dark"
                                  ? "grayscale(1) brightness(3) opacity(0.9)"
                                  : "",
                              paddingRight: "10px",
                            }}
                          />
                        )}
                      </ItemIconContainer>
                    </>
                  )}
                </ItemContainer>
              )
            )}
          </ItemsContainer>
          <Squares>
            <Li
              style={{
                bottom: `${progress + 80}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 15}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 75}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 95}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 50}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 40}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 70}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 20}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 60}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
          </Squares>
        </Container>
      </Element>
      <BackgroundModal
        onClick={() => setCardSelected(0)}
        className={cardSelected !== 0 ? "show" : ""}
      />
    </>
  );
};
const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  padding: 16px;
  padding-top: 100px;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 414px;
  padding-bottom: 100px;
  @media (max-width: 540px) {
    padding: 16px 10px;
    height: 1200px;
  }
`;
const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 540px) {
    margin-bottom: 20px;
  }
`;
const ItemTextsContainer = styled.div`
  padding-right: 0%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 12px;
  font-size: 65px;
  z-index: 10;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 40px;
    margin: 30px 0 10px 0px;
    width: 100%;
  }
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.palette.primary};
  text-align: center;
  margin-bottom: 8px;
  font-size: 23px;
  z-index: 10;
  width: 60%;
  @media (max-width: 540px) {
    text-align: center;
    font-size: 30px;
    margin: 16px 0 10px 10px;
    width: 90%;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  justify-content: center;
  margin-bottom: 50px;
  padding-right: 5%;
  padding-left: 5%;
  gap: 30px;
  @media (max-width: 540px) {
    padding: 0;
    gap: 0;
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background};
  box-shadow: 0px 10px 50px -7px rgba(0, 0, 0, 0.2);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  border-radius: 24px;
  width: 268px;
  justify-content: space-evenly;
  z-index: 10;
  position: absolute;
  height: 180px;
  left: calc(50% - 630px);
  max-width: 24vw;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  bottom: -30px;
  &:nth-child(2) {
    left: calc(50% - 310px);
  }
  &:nth-child(3) {
    left: calc(50% + 10px);
  }
  &:nth-child(4) {
    left: calc(50% + 330px);
  }
  &.selected {
    width: 500px;
    height: 400px;
    z-index: 100;
    left: calc(50% - 230px);
    bottom: calc(-210px);
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  @media (max-width: 540px) {
    flex: 1;
    flex-direction: row;
    align-items: center;
    width: auto;
    position: absolute;
    left: calc(50% - 45vw);
    height: 150px;
    max-width: 90vw;
    width: 90vw;
    top: 500px;
    box-sizing: border-box;
    &:nth-child(2) {
      left: calc(50% - 45vw);
      top: 670px;
    }
    &:nth-child(3) {
      left: calc(50% - 45vw);
      top: 840px;
    }
    &:nth-child(4) {
      left: calc(50% - 45vw);
      top: 1010px;
    }
    &.selected {
      width: 90vw;
      height: 65vh;
      z-index: 100;
      left: calc(50% - (90vw / 2));
      bottom: 0;
      max-width: none;
    }
  }
`;

const ItemTitle = styled.h4`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 24px;
  padding: 0 20px;
  text-align: center;
  cursor: pointer;
  &.selected {
    font-size: 22px;
    animation: ${FadeIn} 0.7s ease-in-out;
    color: #000;
    position: relative;
    & > img {
      position: absolute;
      opacity: 0.1;
      max-height: 85%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      min-height: 240px;
      min-width: 240px;
    }
  }
  &.title {
    font-size: 24px;
    animation: ${FadeIn} 0.7s ease-in-out;
    font-weight: 500;
  }
  @media (max-width: 540px) {
    font-size: 24px;
    text-align: center;
    max-width: 100%;
    padding-right: 90px;
    &.selected {
      font-size: 20px;
      text-align: center;
      max-width: 100%;
      padding: 0px;
    }
    &.title {
      font-size: 24px;
      animation: ${FadeIn} 0.7s ease-in-out;
      padding: 0;
      text-align: center;
    }
  }
`;

const ItemSubtitle = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: calc(100% + 0.5vw);
  display: flex;
  padding-top: 10px;
  cursor: pointer;

  @media (max-width: 540px) {
    font-size: 25px;
    max-width: 80%;
    text-align: left;
  }
`;

const ItemDescription = styled.label`
  color: ${({ theme }) => theme.palette.text};
  font-size: calc(80% + 0.1vw);
  display: flex;
  padding-top: 10px;
  cursor: pointer;

  @media (max-width: 540px) {
    font-size: 20px;
    line-height: 1.5;
  }
`;

const Pulsate = keyframes`
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  `;

const ItemIconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: end;
  padding: 0;
  padding-top: 10px;

  @media (max-width: 540px) {
    top: 0;
    bottom: 0;
    right: 20px;
    position: absolute;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }

  /* ${ItemContainer}:hover & {
    animation: ${Pulsate} 1s ease-in-out;
  } */
`;

const ItemIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  filter: contrast(0.3) saturate(4) hue-rotate(8deg) brightness(1.25);
  @media (max-width: 540px) {
    width: 80px;
    height: 80px;
    padding: 16px 0;
    :nth-child(2) {
      display: none;
    }
  }
`;

const Animate = keyframes`
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(360deg);
        opacity: 0;
        border-radius: 50%;
    }`;

const Squares = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Li = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: none;
  /* animation: ${Animate} 25s linear infinite; */
  transform: rotate(0deg);
  transition: bottom 1s linear, transform 1s linear;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  :nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  :nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  :nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  :nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  :nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  :nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  :nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  :nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  :nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  :nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    :hover {
    }
  }
`;
const BackgroundModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: black;
  opacity: 0;
  z-index: -1;
  transition: all 0.4s ease-in-out;
  &.show {
    opacity: 0.5;
    z-index: 99;
    top: 0;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export default Solutions;
