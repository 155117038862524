import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState, useContext } from "react";
import { scrollContext } from "../../context/scrollContext";

import aws from "../../assets/images/aws.svg";
import gcp from "../../assets/images/gcp.svg";
import ionics from "../../assets/images/ionics.svg";
import python from "../../assets/images/python.svg";
import react from "../../assets/images/react.svg";
import node from "../../assets/images/node.svg";
import angular from "../../assets/images/angular.svg";
import net from "../../assets/images/net.svg";
import gsap from "gsap";
import logo from "../../assets/images/logo.svg";

type ItemType = {
  name: string;
  image: string;
};

const TechStack = () => {
  const stackContainer: any = useRef();
  const firstContainer: any = useRef();
  // const [isVisible, setIsVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(20);
  const [hightElement, setHightElement] = useState(0);
  const [positionToTop, setPositionToTop] = useState(0);
  const {
    scroll: { scrollToTop },
  } = useContext(scrollContext);
  const items: ItemType[] = [
    {
      name: "REACT.JS",
      image: react,
    },
    {
      name: "PYTHON",
      image: python,
    },
    {
      name: "NODE.JS",
      image: node,
    },
    {
      name: "ANGULAR",
      image: angular,
    },
    {
      name: "NET",
      image: net,
    },
    {
      name: "AWS",
      image: aws,
    },
    {
      name: "GCP",
      image: gcp,
    },
    {
      name: "IONIC",
      image: ionics,
    },
  ];

  // const handleObserv = (e: { isIntersecting: any }[]) => {
  //   if (e[0].isIntersecting) {
  //     setIsVisible(true);
  //   } else {
  //     setIsVisible(false);
  //   }
  // };

  function scrolling() {
    const start = positionToTop - 100;
    const end = positionToTop + hightElement;
    const scroll = scrollToTop;
    const progress = Math.floor(((scroll - start) / (end - start)) * 25);
    setProgress(progress);
  }

  useEffect(() => {
    if (firstContainer.current) {
      setHightElement(firstContainer.current.clientHeight);
      setPositionToTop(firstContainer.current.offsetTop);
    }
    if (hightElement & positionToTop) {
      scrolling();
    }
  }, [firstContainer, hightElement, positionToTop, scrollToTop]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const options = {
  //     rootMargin: "0px",
  //     threshold: 0.5,
  //   };
  //   const observer = new IntersectionObserver(handleObserv, options);
  //   observer.observe(stackContainer.current);
  // }, []);

  // useEffect(() => {
  //   let ctx = gsap.context(() => {});
  //   if (isVisible) {
  //     ctx = gsap.context(() => {
  //       gsap.fromTo(
  //         ".item0",
  //         {
  //           opacity: 0,
  //           y: 50,
  //         },
  //         {
  //           y: 0,
  //           opacity: 1,
  //           duration: 1.5,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".item1",
  //         {
  //           opacity: 0,
  //           y: -50,
  //         },
  //         {
  //           y: 0,
  //           delay: 1,
  //           opacity: 1,
  //           duration: 1.5,
  //         }
  //       );
  //     }, stackContainer);
  //   } else {
  //     ctx = gsap.context(() => {
  //       gsap.fromTo(
  //         ".item0",
  //         {
  //           opacity: 1,
  //         },
  //         {
  //           delay: 2,
  //           opacity: 0,
  //         }
  //       );
  //       gsap.fromTo(
  //         ".item1",
  //         {
  //           opacity: 1,
  //         },
  //         {
  //           delay: 2,
  //           opacity: 0,
  //         }
  //       );
  //     }, stackContainer);
  //   }
  //   return () => ctx.revert();
  // }, [isVisible]);
  
  return (
    <Element name="#sec5">
        <Container ref={firstContainer}>
          {/* <Title>{t("techStack")}</Title>

          <ItemsContainer ref={stackContainer}>
            {items.map(({ name, image }, index) => (
              <ItemContainer
              key={index}
                even={index % 2 === 0}
                className={`item${index % 2 === 0 ? 0 : 1} card-${index}`}
                onMouseEnter={() => {
                  gsap.fromTo(
                    `.card-${index}`,
                    {
                      rotateY: -360,
                    },
                    {
                      duration: 1,
                      rotateY: 0,
                    }
                  );
                }}
              >
                <ItemName even={index % 2 === 0}>{name}</ItemName>
                <ItemImage src={image} even={index % 2 === 0} />
              </ItemContainer>
            ))}
          </ItemsContainer> */}
          <Squares>
            <Li
              style={{
                bottom: `${progress + 80}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 15}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 75}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 95}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 50}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 40}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 70}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 20}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
            <Li
              style={{
                bottom: `${progress + 60}%`,
                transform: `rotate(${
                  progress ? Math.floor((progress * 720) / 100) : 0
                }deg)`,
              }}
            >
              <img src={logo} alt="logo" />
            </Li>
          </Squares>
        </Container>
    </Element>
  );
};

const Container = styled.div`
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.gradients[0][0]},  ${theme.palette.gradients[0][1]})`};
  padding: 120px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 540px) {
    display: flex;
    padding: 50px 16px 50px 16px;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 12px;
  font-size: 65px;
  z-index: 10;
  @media (max-width: 540px) {
    text-align: left;
    font-size: 40px;
    width: 90%;
    padding: 0;
  }
`;

// const Subtitle = styled.label`
//   color: #44c1a9;
//   text-align: center;
//   margin-bottom: 20px;
//   max-width: 50%;
//   font-size: 20px;
//   z-index: 10;

//   @media (max-width: 540px) {
//     text-align: justify;
//     font-size: 26px;
//     min-width: 90%;
//     padding: 0;
//   }
// `;

const ItemsContainer = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 50px;
  max-width: 1440px;
  z-index: 10;

  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

const ItemContainer = styled.div<{ even: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ even }) => (even ? "column" : "column-reverse")};
  z-index: 10;

  @media (max-width: 480px) {
    flex-direction: ${({ even }) => (even ? "column" : "column-reverse")};
    width: 25%;
  }
`;

const ItemName = styled.label<{ even: boolean }>`
  margin: ${({ even }) => (even ? "50px 0 0 0" : "0 0 50px 0")};
  background-image: ${({ even, theme }) =>
    !even ? `linear-gradient(to right,#FFFFFF,  #EDF0F6,  #EDF0F6)` : ""};
  background-color: ${({ even, theme }) => (even ? "#1A263A" : "")};
  border-radius: ${({ even }) => (even ? "10px 10px 0 0" : "0 0 10px 10px")};
  padding: 10px 20px;
  color: ${({ even, theme }) => (even ? `${theme.palette.white}` : "")};
  text-align: center;
  width: 50%;
  box-shadow: 0px 15px 30px -10px rgba(0, 0, 0, 0.2);

  @media (max-width: 480px) {
    margin: 0;
    font-size: 12px;
    width: auto;
  }
`;

const ItemImage = styled.img<{ even: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 30px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  box-shadow: 0px 15px 30px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  @media (max-width: 480px) {
    padding: 20px;
    width: 60px;
    height: 60px;
  }
`;

const Animate = keyframes`
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }`;

const Squares = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Li = styled.li`
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: none;
  /* animation: ${Animate} 25s linear infinite; */
  transform: rotate(0deg);
  transition: bottom 1s linear, transform 1s linear;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  :nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  :nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  :nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }
  :nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  :nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }
  :nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }
  :nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }
  :nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  :nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }
  :nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
    :hover {
    }
  }
`;

export default TechStack;
