import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay } from "swiper";
import styled from "styled-components";

import useWindowSize from "../../hooks/useWindowSize";
import { useTheme } from "../../context/theme";

import brand1 from "../../assets/images/brand1.svg";
import brand1Dark from "../../assets/images/brand1-dark.svg";
import brand2 from "../../assets/images/brand2.svg";
import brand3 from "../../assets/images/brand3.png";
import brand4 from "../../assets/images/brand4.png";
import brand5 from "../../assets/images/brand5.svg";
import brand5Black from "../../assets/images/brand5-black.svg";
import brand6 from "../../assets/images/brand6.svg";
import brand6Black from "../../assets/images/brand6-black.svg";
import brand7 from "../../assets/images/brand7.svg";
import brand7Black from "../../assets/images/brand7-black.svg";
import brand9 from "../../assets/images/brand9.png";
import brand10 from "../../assets/images/brand10.png";
import brand11 from "../../assets/images/brand11.png";
import brand12 from "../../assets/images/brand12.svg";
import brand12Dark from "../../assets/images/brand12-dark.svg";

import squares from "../../assets/images/squares.svg";

SwiperCore.use([Pagination, Navigation]);

export default function ContFive() {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const [theme] = useTheme();

  const brands = [
    brand10,
    theme === "light" ? brand1 : brand1Dark,
    brand11,
    theme === "light" ? brand12 : brand12Dark,
    brand2,
    brand4,
    brand3,
    theme === "light" ? brand7 : brand7Black,
    theme === "light" ? brand6 : brand6Black,
    theme === "light" ? brand5 : brand5Black,
    brand9,
  ];

  return (
    <Element name="#sec7">
        <Container>
          {windowSize.width < 540 ? <DecorationTwo src={squares} /> : null}

          <Title>{t("theyHaveReliedOnUs")}</Title>
          <SwiperWrapper
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={1000}
            navigation
            slidesPerView={
              windowSize.width < 450 ? 1 : Math.ceil(windowSize.width / 500)
            }
            className="mySwiper2"
          >
            {brands.map((brand,index) => (
              <SwiperSlide style={{ color: "white" }} key={index}>
                <ImageContainer style={{ filter: theme === "light" ? "none" : "brightness(10000)" }}>
                  <BrandImage
                  style={{ filter: theme === "light" ? "none" : "grayscale(1)" }}
                    src={brand}
                  />
                </ImageContainer>
              </SwiperSlide>
            ))}
          </SwiperWrapper>
        </Container>
    </Element>
  );
}

const Container = styled.div`
  padding: 24px 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.gradients[0][1]} 0%, ${theme.palette.background} 30%, ${theme.palette.background} 70%,  ${theme.palette.gradients[0][1]} 100%)`};

`;
const DecorationTwo = styled.img`
  width: 80px;
  height: 50px;
  position: absolute;
  right: 5%;
  top: 5%;
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text};
  text-align: center;
  margin-bottom: 32px;
  font-size: 55px;
  @media (max-width: 540px) {
    font-size: 40px;
    width: 96%;
    margin: 0;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const BrandImage = styled.img`
  width: 200px;
  height: 60px;
  object-fit: contain;

  @media (max-width: 480px) {
    margin: 0;
    width: 225px;
    height: 100px;
  }
`;
const SwiperWrapper = styled(Swiper)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
  width: calc(90% - 64px);
  padding: 0 32px;
  min-height: 200px;
  @media (max-width: 480px) {
    margin: 0;
    padding: 0px;
    width: calc(100% - 32px);
  }
`;
