import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ReactTypingEffect from "react-typing-effect";
import { useEffect, useRef } from "react";
import gsap from "gsap";

import useWindowSize from "../../hooks/useWindowSize";

import semiCircle from "../../assets/images/semiCircle.svg";
import semicircle2 from "../../assets/images/semicircle2.svg";

import squares from "../../assets/images/squares.svg";
import circles from "../../assets/images/circles.svg";
import devices from "../../assets/images/devices.svg";
import devices2 from "../../assets/images/devices2.svg";

const Hero = () => {
  const refContainer: any = useRef();
  const { t } = useTranslation();
  const bannerTitles: string[] = t("bannerPhrases").split(",");

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".container",
        {
          opacity: 0,
          x: -500,
        },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.5,
        }
      );
      gsap.fromTo(
        ".item",
        {
          opacity: 0,
          scale: 0.5,
        },
        {
          delay: 1,
          scale: 1,
          opacity: 1,
          duration: 1,
          stagger: 0.5,
        }
      );
    }, refContainer);

    return () => ctx.revert();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container id="sec1" ref={refContainer}>
      <SubContainer className="container">
        <Flex>
          <Title>{t("techPartner1")}</Title>
          <ReactTypingEffect
            speed={100}
            eraseDelay={1000}
            eraseSpeed={50}
            typingDelay={1000}
            cursor="|"
            cursorClassName="banner-typing-effect-cursor"
            className="banner-typing-effect-title"
            text={bannerTitles}
            staticText=" "
            displayTextRenderer={(text, i) => {
              return <TitleBranded>{text}</TitleBranded>;
            }}
          />
        </Flex>
        <Title>{t("techPartner2")}</Title>
        <Title>{t("techPartner3")}</Title>

        <Squares src={squares} />
        <Circles src={circles} />
        {/* <Devices className="item" src={isMobile ? devices2 : devices} /> */}
      </SubContainer>

      <SemiCircle src={isMobile ? semicircle2 : semiCircle} />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 100vw;
  background: ${({ theme }) => theme.palette.background};
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.palette.background} 0%, ${theme.palette.background} 60%,  ${theme.palette.gradients[0][1]} 100%)`};
  overflow-x: hidden;
  padding: 32px 16px 64px 16px;
  @media (max-width: 540px) {
    padding: 0 16px;
    width: calc(100vw - 16px);
  }
`;

const SubContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  z-index: 1;
  min-height: 358px;
  width: 100vw;

  @media (max-width: 540px) {
    width: 100;
    padding-top: 10%;
    padding-bottom: 5%;
    min-height: auto;
    text-align: center;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text};
  font-family: Source Sans Pro;
  font-weight: 500;
  font-size: 80px;
  @media (max-width: 480px) {
    font-size: 46px;
    text-align: left;
  }
`;

const TitleBranded = styled(Title)`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: bold;
  padding-left: 10px;

  @media (max-width: 480px) {
    padding: 0;
    text-align: left;
  }
`;

const Squares = styled.img`
  position: absolute;
  width: 75px;
  left: 0;
  bottom: 0;

  @media (max-width: 540px) {
    width: 100px;
    position: relative;
    right: 0;
    bottom: 0;
    margin: 50px 0;
  }
`;

const Circles = styled.img`
  position: absolute;
  width: 100px;
  left: 154px;
  bottom: -15px;

  @media (max-width: 540px) {
    display: none;
  }
`;

const SemiCircle = styled.img`
  width: 17%;
  max-width: 250px;
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: 540px) {
    width: 60%;
    bottom: -20px;
    right: -50px;
    top: auto;
  }
`;

const Devices = styled.img`
  position: absolute;
  width: calc(60% + 1vw);
  right: 50px;
  bottom: -130px;
  z-index: 1;

  @media (max-width: 728px) {
    width: 100%;
    position: relative;
    right: 0;
    bottom: 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 728px) {
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: start;
    flex-direction:  column;
  }
`;

export default Hero;
